<template>
  <div
    ref="searchbarRef"
    class="search-template"
  >
    <div class="search-template__searchbar-wrapper">
      <v-icon size="searchIconSize">
        $icon_video_search
      </v-icon>
      <div
        v-click-outside="hideDropdown"
        class="search-template__searchbar-wrapper--clickable"
        @click="shouldAllowInteraction ? handleSearchbarClick() : null"
        @keydown.down.exact.prevent="
          shouldAllowInteraction ? startArrowKeys() : null
        "
        @keydown.enter.exact="
          ($event) =>
            shouldAllowInteraction ? handleEnterKeyPress($event) : null
        "
        @keydown.esc.exact="shouldAllowInteraction ? hideDropdown() : null"
        @keydown.up.exact.prevent="
          shouldAllowInteraction ? startArrowKeys() : null
        "
      >
        <input
          ref="inputRef"
          v-model="searchQuery"
          :disabled="isDisabled"
          :placeholder="placeholder"
          class="search-template__video-search-input"
        >
        <v-icon
          v-show="searchQuery"
          class="search-template__close-icon"
          size="20"
          :disabled="isDisabled"
          @click.stop="handleClear"
        >
          $icon_close
        </v-icon>
      </div>
    </div>
    <div
      v-show="shouldShowSearchResults"
      class="search-template__search-results"
    >
      <button
        v-for="option in allDropdownOptions"
        :key="option.text"
        class="search-template__result search-template__positive-result"
        @keydown.up.exact.prevent="focusPreviousOption"
        @keydown.down.exact.prevent="focusNextOption"
        @keydown.esc.exact="hideDropdown"
        @keydown.enter.exact="handleEnterKeyPress"
        @click="handleDropdownOpionClick(option)"
      >
        <v-icon :size="24">
          {{ option.icon }}
        </v-icon>
        <div class="search-template__result-data">
          <p class="search-template__search-title">
            {{ option.prependText }}<strong>{{ option.highlightedText }}</strong>{{ option.appendText }}
          </p>
        </div>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  defineEmits,
  defineProps,
  ref,
  watch,
  withDefaults,
} from "vue";

/**
 * Types
 */

interface Option {
  text: string;
  icon: string;
  type: "history" | "generic";
}

type OptionCollection = Array<Option>;

type OptionCollections = Array<OptionCollection>;

interface DropdownOption extends Option {
  prependText: string;
  highlightedText: string;
  appendText: string;
}

type DropdownOptions = Array<DropdownOption>;

type Props = {
  placeholder: string;
  noResult: string;
  optionCollections: OptionCollections;
  value: string;
  isDisabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
});

/**
 * Constants
 */

const MAX_OPTION_COUNT = {
  history: 4,
  generic: 1,
};

/**
 * Utils
 */

const getDropdownOption = (option: DropdownOption) => option;

const highlightMatchingOptions = ({
  options,
  currentSearchQuery,
}: {
  options: OptionCollection;
  currentSearchQuery: string;
}) =>
  options.flatMap((option) => {
    const currentOption = option.text.toLowerCase();

    const substringMatchStartIndex = currentOption.indexOf(currentSearchQuery);
    const substringMatchEndIndex =
      substringMatchStartIndex + currentSearchQuery.length;

    // Omit/remove the option
    if (substringMatchStartIndex === -1) {
      return [];
    }

    // Add dropdown properties to the option
    return [
      getDropdownOption({
        ...option,
        prependText: currentOption.substring(0, substringMatchStartIndex),
        highlightedText: currentOption.substring(
          substringMatchStartIndex,
          substringMatchEndIndex
        ),
        appendText: currentOption.substring(substringMatchEndIndex),
      }),
    ];
  });

/**
 * Component
 */

const emit = defineEmits<{
  (e: "input", value: string): void;
  (e: "enter-key-press", value: string): void;
  (e: "option-click", value: string): void;
}>();

const searchQuery = computed({
  get: () => props.value,
  set: (newValue) => {
    if (newValue !== props.value) {
      emit("input", newValue);
    }
  },
});

const lowerCaseSearchQuery = computed(() => searchQuery.value.toLowerCase());

// History options sorted from option collections
const historyOptions = computed(() => {
  const historyCollection = props.optionCollections.find(
    (collection) => collection[0].type === "history"
  );

  return historyCollection || [];
});

const initialHistoryDropdownOptions = computed<DropdownOptions>(() => {
  // Limit to 5 items
  const matches: OptionCollection = historyOptions.value.filter(
    (_, index) => index <= MAX_OPTION_COUNT.history - 1
  );

  // Add dropdown properties to the options
  return matches.map((match) =>
    getDropdownOption({
      ...match,
      prependText: match.text,
      highlightedText: "",
      appendText: "",
    })
  );
});

const historyDropdownOptions = computed<DropdownOptions>(() => {
  const currentSearchQuery = lowerCaseSearchQuery.value;

  // If any search query
  if (currentSearchQuery && currentSearchQuery.trim()) {
    const matches: OptionCollection = historyOptions.value
      .filter(
        (option) => option.text.toLowerCase().indexOf(currentSearchQuery) !== -1
      )
      .filter((_, index) => index <= MAX_OPTION_COUNT.history - 1);

    return highlightMatchingOptions({
      options: matches,
      currentSearchQuery: lowerCaseSearchQuery.value,
    });
  }

  // If no search query => return initial history dropdown options
  return initialHistoryDropdownOptions.value;
});

const genericOptionCollections = computed(() =>
  props.optionCollections.filter(
    (collection) => collection[0].type === "generic"
  )
);

const initialGenericDropdownOptions = computed<DropdownOptions>(() => {
  const matches: OptionCollection = [];

  // Limit to single suggestion from a collection
  for (let i = 0; i < genericOptionCollections.value.length; i += 1) {
    for (let j = 0; j < genericOptionCollections.value[i].length; j += 1) {
      const shouldSkipOtherOptions = j === MAX_OPTION_COUNT.generic - 1;
      matches.push(genericOptionCollections.value[i][j]);

      if (shouldSkipOtherOptions) {
        break;
      }
    }
  }

  // Add dropdown properties to the options
  return matches.map((match) =>
    getDropdownOption({
      ...match,
      prependText: match.text,
      highlightedText: "",
      appendText: "",
    })
  );
});

const genericDropdownOptions = computed<DropdownOptions>(() => {
  const currentSearchQuery = lowerCaseSearchQuery.value;

  // If any search query
  if (currentSearchQuery && currentSearchQuery.trim()) {
    // Collection of first match from each set
    const matches: OptionCollection = [];

    genericOptionCollections.value.forEach((collection) => {
      // Find the first match
      const match = collection.find(
        (option) => option.text.toLowerCase().indexOf(currentSearchQuery) !== -1
      );

      if (match) {
        matches.push(match);
      }
    });

    return highlightMatchingOptions({
      options: matches,
      currentSearchQuery: lowerCaseSearchQuery.value,
    });
  }

  // If no search query => return initial generic dropdown options
  return initialGenericDropdownOptions.value;
});

const allDropdownOptions = computed<DropdownOptions>(() => [
  ...historyDropdownOptions.value,
  ...genericDropdownOptions.value,
]);

const focusedIndex = ref(-1);

const inputRef = ref<HTMLInputElement | null>(null);

const resetFocusedIndex = () => {
  if (inputRef.value) {
    inputRef.value.blur();
  }
  focusedIndex.value = -1;
};

const isDropdownVisible = ref<boolean>(false);

const handleSearchbarClick = () => {
  isDropdownVisible.value = true;
  focusedIndex.value = -1;
};

const hideDropdown = () => {
  isDropdownVisible.value = false;
  resetFocusedIndex();
};

const searchbarRef = ref<HTMLDivElement | null>(null);

const focusPreviousOption = () => {
  if (focusedIndex.value > 0) {
    focusedIndex.value -= 1;
  }
};

const focusNextOption = () => {
  if (focusedIndex.value < allDropdownOptions.value.length - 1) {
    focusedIndex.value += 1;
  }
};

const startArrowKeys = () => {
  if (isDropdownVisible.value) {
    focusNextOption();
  }
};

const focusOption = () => {
  if (searchbarRef.value) {
    searchbarRef.value?.children?.[1].children?.[focusedIndex.value]?.focus();
  }
};

watch(focusedIndex, () => {
  focusOption();
});

const handleDropdownOpionClick = (dropdownOption: DropdownOption) => {
  searchQuery.value = dropdownOption.text;

  emit("option-click", dropdownOption.text);
  hideDropdown();
};

const handleEnterKeyPressForNewQuery = (term: string) => {
  emit("enter-key-press", term);
  hideDropdown();
};

const handleEnterKeyPress = (event: KeyboardEvent) => {
  const currentOption = allDropdownOptions.value[focusedIndex.value];

  // For dropdown options - which already exists
  if (currentOption) {
    handleDropdownOpionClick(currentOption);
    return;
  }

  // For a new term - which is not there in the suggestions
  const newTerm = event.target?.value;
  if (newTerm) {
    handleEnterKeyPressForNewQuery(newTerm);
  }
};

const handleClear = () => {
  searchQuery.value = "";

  if (inputRef.value) {
    inputRef.value.focus();
    isDropdownVisible.value = true;
  }
};

const shouldShowSearchResults = computed(
  () => isDropdownVisible.value && allDropdownOptions.value.length
);

const shouldAllowInteraction = computed(() => !props.isDisabled);
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/main.scss";

.search-template {
  position: relative;
  width: 100%;
  z-index: 100; // 100 works

  &__searchbar-wrapper {
    box-sizing: border-box;
    display: flex;
    padding: 0 12px;
    border: 1px solid $secondaryMedium;
    border-radius: 4px;
    background-color: $primaryWhite;

    &--clickable {
      display: flex;
      height: 34px;
      width: 100%;
      align-items: center;
    }

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    }

    &:focus-within {
      border-color: $accent;
    }
  }

  &__video-search-input {
    @include body-2;
    height: 34px;
    color: $primary;
    border: 0;
    outline: 0;
    width: 100%;
    padding-left: 4px;

    &::placeholder {
      color: $secondaryMedium;
    }

    &:focus::placeholder {
      color: transparent;
    }

    &__close-icon {
      align-self: flex-end;
    }
  }

  &__search-results {
    @include body-2;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    border: 1px solid $elements;
    border-radius: 4px;
    background: $primaryWhite;
    padding: 12px;
    overflow: auto overlay;
    margin-top: 4px;
    box-shadow: 0px 2px 12px rgba(42, 52, 64, 0.08);

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: white;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondaryMedium;
      border-radius: 8px;
      background-clip: padding-box;
      border: 4px solid white;
    }
  }

  &__result {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    height: 36px;
    overflow: hidden;

    &:focus {
      outline: none;
      background-color: $accentClear;
    }
  }

  &__search-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $primaryLight;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    strong {
      color: $primary;
    }
  }

  &__search-category {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $secondary;
  }

  &__result-data {
    flex: 1;
    text-align: left;
    overflow: hidden;

    p {
      margin: 0;
    }
  }

  &__video-search-icon {
    height: 30px;
    width: 30px;
  }

  &__positive-result {
    &:hover {
      background-color: $accentClear;
    }

    &:active {
      background-color: $secondaryLight;
    }
  }

  &__no-result {
    line-height: 36px;

    p {
      margin: 0;
    }
  }
}
</style>
