<template>
  <v-breadcrumbs
    :items="indexedBreadcrumbs"
    class="breadcrumbs-container"
  >
    <template #item="{ item }">
      <v-breadcrumbs-item
        :class="[
          'breadcrumb',
          item.breadcrumbIndex === (indexedBreadcrumbs && indexedBreadcrumbs.length - 1) &&
            'breadcrumb--last',
        ]"
        :data-testid="item.breadcrumbIndex"
        @click="onBreadcrumbClick(item.breadcrumbIndex, item.onClick)"
      >
        {{ item.title }}
      </v-breadcrumbs-item>
    </template>
    <template #divider>
      <v-icon> {{ dividerIcon ? dividerIcon : "$icon_arrow_right" }} </v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type breadcrumb = {
  title: string;
  onClick?: () => void;
};

interface indexedBreadcrumb extends breadcrumb {
  breadcrumbIndex?: number;
}

const emit = defineEmits<{
  (e: "input", value?: breadcrumb[]): void;
}>();

const props = defineProps<{
  value?: breadcrumb[];
  dividerIcon?: string;
}>();

const indexedBreadcrumbs = computed({
  get: () => {
    // Appending a breadcrumbIndex key
    return props.value && props.value.map((breadcrumb: breadcrumb, index) => {
      return {
        ...breadcrumb,
        breadcrumbIndex: index,
      };
    });
  },
  set: (newBreadcrumbNavigation?: indexedBreadcrumb[]) => {
    emit(
      "input",
      newBreadcrumbNavigation && newBreadcrumbNavigation.map(
        function (item) {
          delete item.breadcrumbIndex;
          return item;
        }
      )
    );
  },
});

function onBreadcrumbClick(index: number, callback?: () => void) {
  if (index < indexedBreadcrumbs.value!.length - 1) {
    const updatedBreadcrumbNavigation = indexedBreadcrumbs.value && indexedBreadcrumbs.value.filter(
      (breadcrumb: indexedBreadcrumb) => breadcrumb.breadcrumbIndex! <= index
    );
    indexedBreadcrumbs.value = updatedBreadcrumbNavigation;
  }

  callback && callback();
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

ol.breadcrumbs-container,
ul.breadcrumbs-container {
  padding: 0 !important;
}

::v-deep .breadcrumb {
  .v-breadcrumbs__item {
    @include subtitle-1;
    color: $secondary;
    cursor: pointer;
  }

  &:hover {
    .v-breadcrumbs__item {
      color: $primary;
    }
  }

  &--last {
    .v-breadcrumbs__item {
      color: $primary;
    }
  }
}
</style>
