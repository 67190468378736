<template>
  <div
    class="time-picker"
    :class="{'time-picker--full-width': fullWidth}"
  >
    <v-menu
      :id="attachId ? attachId : false"
      v-model="openPopup"
      :close-on-content-click="false"
      :content-class="`time-picker__popup ${dark === true ? 'time-picker__popup--dark' : ''}`"
      offset-y
      :min-width="popupWidth"
      :max-width="popupWidth"
      :left="isAttached ? false : true"
      :top="top"
      :nudge-top="nudgeTop ? nudgeTop : 0"
      :nudge-bottom="nudgeBottom ? nudgeBottom : 0"
      :nudge-left="nudgeLeft ? nudgeLeft : 0"
      :nudge-right="nudgeRight ? nudgeRight : 0"
      :transition="false"
      :attach="isAttached"
    >
      <template #activator="{ on, attrs }">
        <div
          :id="attachId ? attachId : ''"
          v-bind="attrs"
          v-on="on"
          @click="click"
        >
          <slot name="activator">
            <text-field
              :value="defaultValue ? defaultValue : displayText"
              right-icon="$icon_history"
              label=""
              :readonly="true"
              :class="['time-picker__input-field', {
                'time-picker__input-field--open': openPopup === true,
                'time-picker__input-field--dark': dark === true,
                'time-picker__input-field--full-width': fullWidth === true
              }]"
              @clickAppend="click"
            />
            <div
              v-if="openPopup && defaultValue.length === 0"
              class="time-picker__placeholder"
            >
              <span>{{ placeholder }} </span>
            </div>
          </slot>
        </div>
      </template>
      <div
        ref="timePickerPopup"
        class="time-picker__popup--time-columns"
      >
        <div
          v-for="(col, i) in columns"
          :key="i"
          class="time-picker__popup--time-column"
        >
          <div class="header">
            {{ col.title }}
          </div>
          <div
            :class="['value-container', {
              'value-container-ampm':col.type === 'ampm',
              'hour' : col.type === 'hour',
              'minute' : col.type === 'minute',
              'second' : col.type === 'second'
            }]"
          >
            <div
              v-for="(item, j) in col.list"
              :key="j"
              :class="[{
                'time-value__blank': !item.length,
                'time-value': item.length,
                'active-hh' : col.type === 'hour' && parseValue(displayText).hour == item,
                'active-mm' : col.type === 'minute' && parseValue(displayText).minute == item,
                'active-ss' : col.type === 'second' && parseValue(displayText).second == item,
                'active-ampm' : col.type === 'ampm' && parseValue(displayText).ampm == item,
                'active-last-column-mm' : col.type === 'minute' && parseValue(displayText).minute == item && !props.showSecond
              }]"
              @click="item !== '' && selectTime(item, col.type)"
            >
              {{ item }}
            </div>
          </div>
          <div
            :class="[{
              'highlight-hh' : col.type === 'hour',
              'highlight-mm' : col.type === 'minute',
              'highlight-ss' : col.type === 'second',
              'highlight-ampm' : col.type === 'ampm',
              'highlight-last-column-mm' : col.type === 'minute' && !props.showSecond
            }]"
          >
            {{ getSelectedValue(col.type) }}
          </div>
        </div>
      </div>
      <div
        class="time-picker__popup--footer"
        :class="{'justify-end': hideNowButton}"
      >
        <button-common
          v-show="!hideNowButton"
          type="clear"
          color="primary"
          data-testid="time-picker-footer-now-btn"
          @click="now"
        >
          {{ nowText }}
        </button-common>
        <button-common
          type="clear"
          color="accent"
          data-testid="time-picker-footer-done-btn"
          @click="onDone"
        >
          {{ doneText }}
        </button-common>
      </div>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, nextTick } from 'vue'
import TextField from '../text-inputs/TextField.vue'
import ButtonCommon from '../buttons/ButtonCommon.vue'

type Props = {
  nowText: string,
  doneText: string,
  value: string | Date,
  use12h: boolean,
  showSecond: boolean,
  attachId?: string,
  hourText?: string,
  minuteText?: string,
  secondText?: string,
  placeholderText?: string,
  top?: boolean,
  nudgeBottom?: number,
  nudgeTop?: number,
  nudgeLeft?: number,
  nudgeRight?: number,
  dark?: boolean,
  limits?: {
    hour: number,
    minute: number,
    second: number
  },
  timeZone: string,
  fullWidth?: boolean,
  isAttached?: boolean,
  hideNowButton?: boolean
}

const props = defineProps<Props>();
const valueProp = computed(() => {
  if (typeof props.value === 'string') {
    const dateStringWithoutMilliseconds = new Date(props.value.replace(/\.\d+/, '')); // milliseconds in combination with a meridiem is not supported by Date.parse in every browser
    return dateStringWithoutMilliseconds;
  } else {
    return props.value;
  }
});
const displayText = ref<string>('');
const timePickerPopup = ref<HTMLDivElement>();
const scrollToSelectedTimer = ref<number>();
const menuOpenTimer = ref<number>();
const scrollTimer = ref<number>();
const pickerStepHeight = 28;

const emit = defineEmits<{
  (e: "input", value: string): void;
  (e: "close"): void;
  (e: "open"): void;
}>();

const defaultValue = computed({
  get() {
    return !openPopup.value ? format(new Date(valueProp.value)) : displayText.value
  },
  set(newValue) {
    displayText.value = newValue;
  }
})

const openPopup = ref<boolean>(false);

watch(
  () => openPopup.value,
  async () => {
    if(openPopup.value) {
      displayText.value = format(new Date(valueProp.value));
      await nextTick();
      clearTimeout(scrollToSelectedTimer.value);
      clearTimeout(scrollTimer.value);
      scrollToSelectedTimer.value = setTimeout(scrollToSelected, 100); //this has been used to show the values selected and scrolled to correct position on popup open
      scrollTimer.value = setTimeout(registerScrollEvents,200);
      emit("open");
    } else {
      unRegisterScrollEvents();
      clearTimeout(scrollToSelectedTimer.value);
      clearTimeout(scrollTimer.value);
      emit("close");
    }
  }
);

watch(
  () => displayText.value,
  async (newVal, oldVal) => {
    await nextTick();
    if(newVal.length !== oldVal.length) {
      scrollToSelected(undefined);
    } else {
      const {hour: newHour, minute: newMinute, second: newSecond, ampm: newAmpm } = parseValue(newVal);
      const {hour: oldHour, minute: oldMinute, second: oldSecond, ampm: oldAmpm } = parseValue(oldVal);
      const affectedColumns = [];
      newHour!== oldHour && affectedColumns.push('hour');
      newMinute!== oldMinute && affectedColumns.push('minute');
      newSecond!== oldSecond && affectedColumns.push('second');
      newAmpm!== oldAmpm && affectedColumns.push('ampm');
      newAmpm!== oldAmpm && props.limits && affectedColumns.push('hour');
      scrollToSelected(affectedColumns);
    }
  }
);

const columns = computed(() =>  {
  const cols = [];
  cols.push({ type: 'hour', title: props.hourText ? props.hourText : 'hh', list: getHoursList() });
  cols.push({ type: 'minute', title: props.minuteText ? props.minuteText : 'mm', list: getMinutesList() });
  if (props.showSecond) cols.push({ type: 'second', title: props.secondText ? props.secondText : 'ss', list: getSecondsList() });
  if (props.use12h) cols.push({ type: 'ampm', list: getAMPMList() });

  return cols.filter(v => v.list.length > 0);
});

const popupWidth = computed(() =>  {
  if(props.showSecond && props.use12h) {
    return 268;
  } else if(!props.showSecond && !props.use12h) {
    return 142;
  } else {
    return 202;
  }
});

function applyLimits(list: string[], unit: 'hour' | 'minute' | 'second' | 'meridiem') {
  if (!props.limits) {
    return list;
  }

  if (unit === 'meridiem') {
    if(props.limits.hour < 12) {
      selectTime('AM', 'ampm')
      return ['AM'];
    } else {
      return ['AM', 'PM']
    }
  } else if (!isNaN(props.limits[unit])) {
    const hourValue = parseInt(parseValue(displayText.value).hour);
    const minuteValue = parseInt(parseValue(displayText.value).minute);

    if (props.use12h && props.limits.hour >= 12) {
      const meridiemValue = parseValue(displayText.value).ampm;

      if (meridiemValue === 'PM') {
        if(unit === 'hour') {
          return list.filter(item => (parseInt(item) <= props.limits.hour - 12) || parseInt(item) === 12 && props.limits.hour === 12);
        } else if (unit === 'minute' && parseInt(parseValue(displayText.value).hour) + 12 < props.limits.hour) {
          return list
        } else if (unit === 'second' && (hourValue + 12 < props.limits.hour || minuteValue < props.limits.minute )) {
          return list
        } else return list.filter(item => parseInt(item) <= props.limits![unit]);
      } else if (meridiemValue === 'AM') {
        return list
      } else return list.filter(item => parseInt(item) <= props.limits![unit]);
    } else {
      const hourValueSmallerThanLimit = hourValue < props.limits.hour;

     if (unit === 'minute' && hourValueSmallerThanLimit) {
        return list;
      } else if(unit === 'second' && (hourValueSmallerThanLimit || parseInt(parseValue(displayText.value).minute) < props.limits.minute)) {
        return list;
      } else {
        return list.filter(item => parseInt(item) <= props.limits![unit]);
      }
    }
  }
}

function getHoursList() {
  const hour = generateOptions(props.use12h ? 12 : 24).map(num => {
    let text = padNumber(num);
    if (props.use12h) {
      if (num === 0) {
        text = '12';
      }
    }
    return text;
  });
  return appendEmptyElements(applyLimits(hour, 'hour'))

}

function getMinutesList() {
  const min =  generateOptions(60).map(num => {
    return padNumber(num);
  });
  return appendEmptyElements(applyLimits(min, 'minute'))
}

function getSecondsList() {
  const sec = generateOptions(60).map(num => {
    return padNumber(num);
  });
  return appendEmptyElements(applyLimits(sec, 'second'))
}

function getAMPMList() {
  return appendEmptyElements(applyLimits(['AM','PM'], 'meridiem'));
}

function appendEmptyElements(val: string[]) {
  const arr = Array(3).fill('');
  return arr.concat(val).concat(arr);

}
function generateOptions (length: number) {
  const arr = [];
  for (let i = 0; i < length; i += 1) {
    arr.push(i);
  }
  return arr;
}

function now() {
  displayText.value = format(new Date(new Date().toLocaleString('en-US', { timeZone: props.timeZone })));
}

const padNumber = (value: number) => {
  return value < 10 ? `0${value}` : `${value}`;
};

function onDone() {
  // safari does not support date with `-` in it  
  const date = new Date("1970/12/12 "+displayText.value).toLocaleString().replace(/-/g, '/');
  emit("input",  date);
  openPopup.value = false;
}

function format(val: Date) {
  const minutes = padNumber(val.getMinutes());
  const seconds = padNumber(val.getSeconds());
  const hours = val.getHours();

  if(!props.use12h) {
    return `${padNumber(val.getHours())}:${minutes}${props.showSecond? ":"+seconds : ''}`;
  } else {
    const ampm = hours >= 12 ? 'PM' : 'AM';
    let hoursVal = hours % 12;
    hoursVal = hoursVal ? hoursVal : 12;
    return `${padNumber(hoursVal)}:${minutes}${props.showSecond? ":"+seconds : ''} ${ampm}`;
  }
}

function parseValue(val: string) {
  if(!val) {
    return {hour: '', minute: '', second: '', ampm: ''}
  }
  const value = val.split(':');
  const hour = value[0]? value[0] : '';
  const minute = value[1]? value[1].split(' ')[0] : '';
  const second = props.showSecond && value[2]? value[2].split(' ')[0] : '';
  const ampm = props.use12h ? val.slice(-2) : '';
  return {hour, minute, second, ampm};
}

async function selectTime(val: string, type: "hour" | "minute" | "second" | "ampm") {

  let {hour, minute, second, ampm} = parseValue(displayText.value);
  switch(type) {
    case 'hour':
      hour = val;
      break;
    case 'minute':
      minute = val;
      break;
    case 'second':
      second = val;
      break;
    case 'ampm':
      ampm = val
      break;
    default:
      break;
  }

  displayText.value = `${hour}:${minute}${props.showSecond ? ':'+second: ''}${props.use12h? ' '+ampm : ''}`;
}

function click() {
  if(!openPopup.value) {
    clearTimeout(menuOpenTimer.value);
    menuOpenTimer.value = setTimeout(()=> openPopup.value = true, 100); //vuetify bug https://github.com/vuetifyjs/vuetify/issues/13891
  }
}

function scrollToSelected(affectedColumns: string[] | undefined) {
  if(!timePickerPopup.value) {
    return;
  }

  const containers = Array.from(timePickerPopup.value.querySelectorAll('.value-container'));
  containers.forEach((container)=> {
    if(!affectedColumns || (container.classList.contains('value-container-ampm') && affectedColumns.includes('ampm')) ||
      (container.classList.contains('hour') && affectedColumns.includes('hour')) ||
      (container.classList.contains('minute') && affectedColumns.includes('minute')) ||
      (container.classList.contains('second') && affectedColumns.includes('second')) ) {
      const childArr = Array.from(container.children);
      const active = childArr.find((child: Element)=> child.className.includes('active')) as HTMLDivElement;
      active && active.scrollIntoView({block: "center"});
    }
  });
}

const placeholder = computed(() =>  {
  return props.placeholderText ? props.placeholderText : 'hh:mm:ss AM';
});

function registerScrollEvents() {
  if(!timePickerPopup.value) {
    return;
  }
  const containers = Array.from(timePickerPopup.value.querySelectorAll('.value-container')) as HTMLDivElement[];

  containers.forEach((element)=> {
    const isAmPmContainer = element.classList.contains('value-container-ampm')

    !isAmPmContainer && element.addEventListener("scroll", infiniteScrollListener, { passive: false });
  });
}

function unRegisterScrollEvents() {
  if(!timePickerPopup.value) {
    return;
  }
  const containers = Array.from(timePickerPopup.value.querySelectorAll('.value-container')) as HTMLDivElement[];
  containers.forEach((element)=> {
    const isAmPmContainer = element.classList.contains('value-container-ampm')
    !isAmPmContainer && element && element.removeEventListener("scroll", infiniteScrollListener);
  });
}

function infiniteScrollListener(event: Event) {
  const element = event.target as HTMLDivElement;
  if (!timePickerPopup.value) {
    return;
  }

  const childArr = Array.from(element.children);
  const scrollTop = element.scrollTop;
  const middlePosition = Math.round((scrollTop/pickerStepHeight) + 3);
  const middleElement = childArr[middlePosition] as HTMLElement;
  if (!middleElement) {
    return;
  }

  middleElement.scrollIntoView({
    block: "center",
    behavior: 'smooth'
  });

  if(element.classList.contains("hour")) {
    selectTime(middleElement.innerText, 'hour');
  } else if(element.classList.contains("minute")) {
    selectTime(middleElement.innerText, 'minute');
  } else if(element.classList.contains("second")) {
    selectTime(middleElement.innerText, 'second');
  }
}

function getSelectedValue(type: "hour" | "minute" | "second" | "ampm") {
  const parsedVal = parseValue(displayText.value)
  switch(type) {
    case 'hour':
      return parsedVal.hour;
    case 'minute':
      return parsedVal.minute;
    case 'second':
      return parsedVal.second;
    case 'ampm':
      return parsedVal.ampm;
    default:
      return;
  }
}

(function useRemount() {
  watch(() => [props.use12h, props.showSecond], async () => {
    if(openPopup.value) {
      openPopup.value = false;
      await nextTick()
      openPopup.value = true;
    }
  })
})()


</script>

<style lang="scss">
@import "../../assets/styles/main";

@mixin cell {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include body-2;
}

.time-picker {
  display: inline-block;

  &__placeholder {
    @include body-2;
    color: $secondaryMedium;
    position: relative;
    top: -28px;
    z-index: 2;
    padding-left: 12px;
  }

  &__input-field {
    width: 142px;
    @include body-2;

    &--open {
      .v-input__slot {
        & fieldset {
          color: $accent  !important;
          border: 1px solid $accent !important;
        }
        &:focus-within {
          & fieldset {
            background: none  !important;
            color: $accent  !important;
          }
        }

        input {
          text-transform: uppercase;
        }
      }
    }

    &--dark {
      .v-input .v-input__slot {
        fieldset {
          background: unset;
          backdrop-filter: unset;
          border: unset;
        }

        &:focus-within {
          & fieldset {
            color: $accent  !important;
            border: 1px solid $accent !important;
          }
        }
        input, svg {
          color: $primaryWhite !important;
        }
        background: rgba(33, 42, 52, 0.72);
        backdrop-filter: blur(2px);
      }
    }

    &--full-width {
      width: 100%;
    }
  }

  &__popup {
    height: 284px;
    border: 1px solid $elements;
    box-shadow: none !important;
    background-color: $backgrounds !important;
    padding: 0 !important;
    overflow: hidden;

    &--time-columns {
      display: flex;
      height: calc(100% - 43px);
    }

    &--time-column {
      flex: 1;
      border-right: 1px solid $elements;
      padding: 8px 0;
      &:last-child {
        border-right: none;
      }

      .value-container {
        overflow-y: auto;
        height: calc(100% - 28px);

        &-ampm {
          overflow: hidden;
        }

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &:hover::-webkit-scrollbar-thumb {
          background-color: $secondaryMedium;
          border-radius: 8px;
          background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: $secondaryMedium !important;
        }

      }

      .highlight-common {
        width: 100%;
        height: 28px;
        position: relative;
        top: -112px;
        background: $accent;
        color: $primaryWhite;
        @include subtitle-2;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        &:hover, &:active {
          margin:0;
        }
      }

      .highlight {
        &-hh {
          @extend .highlight-common;
          border-radius: 4px 0 0 4px;
          margin-left: 4px !important;
          width: calc(100% - 4px);
          padding-right: 8px;
        }

        &-mm {
          @extend .highlight-common;
          border-radius: 0;
          padding-right: 4px;
        }

        &-ss {
          @extend .highlight-common;
          border-radius: 0 4px 4px 0;
          margin-right: 4px !important;
          width: calc(100% - 4px);
        }

        &-ampm {
          @extend .highlight-common;
          width: calc(100% - 8px);
          border-radius: 4px;
          margin: 0 4px !important;
        }

        &-last-column-mm {
          @extend .highlight-ss;
          padding-right: 0;
        }
      }

      .time-value {
        @include cell;
        border-radius: 4px;
        cursor: pointer;
        scroll-snap-align: center;

        &__blank {
          height: 28px;
        }

        &:hover {
          margin-left: 4px;
          padding-right: 4px;
        }

        &-active {
          background: $accent;
          color: $primaryWhite;
          @include subtitle-2;

          &:hover, &:active {
            margin:0;
          }
        }

        &.active-hh{
          color: $primaryWhite;
        }
        &.active-mm{
          color: $primaryWhite;
        }
        &.active-ss{
          color: $primaryWhite;
        }
        &.active-ampm{
          color: $primaryWhite;
        }
      }

      .header {
        @include cell;
        color: $secondaryMedium;
      }

    }

    &--footer {
      border-top: 1px solid $elements;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;
      margin: 0 4px;
    }

    &--dark {
      background: $primary !important;
      border: 1px solid $primary;
      [class$="time-column"] {
        border-color: $primaryMedium !important;

        [class$="time-value"] {
          color: $primaryWhite;
          &:hover {
            background: $accentDark;
          }
        }
      }
      [class$="--footer"] {
        border-color: $primaryMedium;
        .primaryBtn {
          color: $primaryWhite;
          &:hover{
            background: $primaryLight !important;
          }
          &:focus-visible {
            background: none;
            border: 1px solid $primaryWhite;
          }
          &:active {
            background: $secondaryMedium !important;
          }
        }
        .accentBtn {
          &:hover{
            background: $primaryLight !important;
          }
          &:focus-visible:after {
            background: none;
            border: 1px solid $accent !important;
          }
          &:active {
            background: $accentLight !important;
          }
        }
      }
      .time-value{
        color: transparent;
      }
      .active-hh{
        color: transparent;
      }
    }
  }

  &--full-width {
    width: 100%;
  }
}

</style>
