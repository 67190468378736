<template>
  <div
    v-click-outside="hideMultiSelectItems"
    class="multiselect-box"
  >
    <button
      class="multiselect-box__select"
      :disabled="disabled"
      :class="{ 'multiselect-box__select--disabled': disabled, 'multiselect-box__select--active': selectActive && !errorMessage, 'multiselect-box__select--error': errorMessage }"
      type="button"
      @click="selectActive = !selectActive;"
    >
      {{ selectedItemsLabel }}
      <v-icon
        v-show="!errorMessage"
        :size="24"
        class="ml-auto"
        :color="disabled ? 'secondaryLight' : 'secondaryMedium'"
      >
        $icon_arrow_down
      </v-icon>
      <v-icon
        v-show="errorMessage"
        :size="24"
        color="negative"
        class="ml-auto"
      >
        $icon_attention
      </v-icon>
    </button>
    <div 
      v-show="selectActive"
      class="multiselect-box__content"
    >
      <div class="mb-2">
        <SearchBar
          v-model="currentSearch"
          :collections="[]"
          :show-collection="false"
          :placeholder="labelStrings.searchPlaceholder ? labelStrings.searchPlaceholder : ''"
        />
      </div>
      <div class="multiselect-box__items">
        <div
          class="multiselect-box__item--normal"
        >
          <CheckBox
            v-model="isSelectedAll"
            :label="labelStrings.allLabel"
            :indeterminate="isIndeterminate"
            class="multiselect-box__item__checkbox"
          />
        </div>
        <div>
          <div
            v-for="item in selectedItems"
            v-show="selectedItems"
            :key="item.text"
            class="multiselect-box__item--normal"
          >
            <CheckBox
              :label="item.text"
              :value="true"
              class="multiselect-box__item__checkbox"
              @change="clickItem(item)"
            />
          </div>
          <v-divider
            v-show="isIndeterminate"
            class="my-1 mr-1"
          />
          <div
            v-if="searchResultItems"
          >
            <div
              v-for="item in searchResultItems"
              :key="item"
              class="multiselect-box__item--searched"
            >
              <CheckBox
                :label="item.text"
                :value="false"
                class="multiselect-box__item__checkbox"
                @change="clickItem(item)"
              />
            </div>
          </div>
        </div>
        <div 
          v-if="currentSearch && searchResultItems.length === 0"
          class="multiselect-box__no-results"
        >
          <p>{{ labelStrings.noResultsLabel }}</p>
        </div>
        <div v-if="selectedItems.length !== originalItems.length">
          <div
            v-for="collection of availableCollections"
            :key="collection.name"
          >
            <p
              v-if="collection.items.length > 0 && collections.length > 1"
              class="multiselect-box__category"
            >
              {{ collection.name }}
            </p>
            <div
              v-for="item in collection.items"
              :key="item.value"
              class="multiselect-box__item--normal"
              @click="clickItem(item)"
            >
              <CheckBox
                :label="item.text"
                :value="selectedItems.some(selectedItem => selectedItem.value === item.value)"
                class="multiselect-box__item__checkbox"
                @change="clickItem(item)"
              />
            </div>
          </div>
        </div> 
      </div>
    </div>
    <div
      v-show="errorMessage"
      class="multiselect-box__error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import CheckBox from "../../selection-controls/CheckBox.vue";
import SearchBar from "../../text-inputs/searchBar/SearchBar.vue"
import { Collection, SelectBoxItem, LabelStrings } from '../types';
import { clickItemHandler, selectedItemsLabelHandler } from "../multiSelectUtils";

const props = defineProps<{
  disabled?: boolean,
  value?: SelectBoxItem[],
  collections: Collection[],
  labelStrings: LabelStrings,
  errorMessage?: string,
}>();

const emit = defineEmits<{
  (e: "input", value?: SelectBoxItem[]): void;
}>();

// CONVERTED PROPS
const originalItems = computed(() =>
  props.collections.flatMap((collection:Collection) => collection.items)
);
const inRange = (num: number, a: number, b=0) => (Math.min(a,b) <= num && num < Math.max(a,b));
const isIndeterminate = ref(props?.value && inRange(props?.value?.length, 1, originalItems.value.length));

// SELECTED ITEMS
const selectedItems = computed({
  get: () => props.value ? props.value : [],
  set: (newValue) => {
    isIndeterminate.value = inRange(newValue.length, 1, originalItems.value.length);
    emit('input', newValue);
  }
});

// AVAILABLE ITEMS
const availableCollections = computed(() => {
  return props.value ? props.collections.map((collection:Collection) => {
    return {
      name: collection.name,
      items: collection.items.filter(item => !(selectedItems.value.some(value => value.value === item.value)))
    }
  }) : props.collections
})

const availableItems = computed(() => {
  return availableCollections.value.flatMap((collection:Collection) => collection.items)
})

// SEARCH
const currentSearchLower = computed(() => currentSearch.value.toLowerCase());

const searchResultItems = computed(
  () => {
    return availableItems.value.filter((item) => {
      const itemName = item.text.toLowerCase();
      return currentSearchLower.value ? itemName.indexOf(currentSearchLower.value) > -1 : false;
    });
  });

const isSelectedAll = computed({
  get: () => props?.value?.length === originalItems.value.length,
  set: (newValue) => {
    selectedItems.value = newValue ? originalItems.value : [];
  }
})

// SELECT ALL
const selectActive = ref<boolean>(false);

const currentSearch = ref<string>('');
// LABEL
const selectedItemsLabel = computed(() =>
  selectedItemsLabelHandler(isSelectedAll.value, selectedItems.value, props.labelStrings)
);

// EVENT HANDLERS
const hideMultiSelectItems = () => { selectActive.value = false; };

// Function responsible for removing/adding items allFilteredItems
const clickItem = (item: SelectBoxItem)  => {
  selectedItems.value = clickItemHandler(item, selectedItems.value);
}

watch(selectActive, (newValue, oldValue) => {
  if (!newValue && newValue !== oldValue) {
    currentSearch.value = '';
  }
});
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/main";
@import "../multiSelectStyles";
.multiselect-box {
  &__item {
    height: 28px;
    &__checkbox {
      padding-top: 2px !important;
    }
    &--normal {
      &:hover {
        background: $accentClear;
        border-radius: 4px;
        margin-right: 4px;
      }
    }
    &--searched {
      background: $accentClear;
      border-radius: 4px;
      margin-right: 4px;
    }
  }
  &__searched-items {
    background: $accentClear;
    box-shadow: none;
    border-radius: 4px;
  }

  &__category {
    @include body-3;
    color: $secondaryMedium;
    margin: 4px 0 !important;
  }

  &__no-results {
    display: flex;
    align-items: center;
    height: 44px;
    p {
      @include body-2;
      margin: 0 !important;
    }
  }
}
</style>
