<template>
  <div
    v-click-outside="onClickOutside"
    class="chips-select"
  >
    <chips-input
      v-model="items"
      :placeholder="placeholder"
      small-input
      @input-change="change"
      @input-focus="menuOpen = true"
      @input="input"
      @removed="(removedValue) => emit('removed', removedValue)"
    />
    <div
      v-if="menuOpen"
      class="chips-select__menu"
    >
      <div v-if="filteredItems.length">
        <div
          v-if="allSelector.length"
          class="menu__item"
          @click="selectAll"
        >
          {{ allSelector }}
        </div>
        <div
          v-for="item in filteredItems"
          :key="item.text"
          :class="`menu__item ${items && items.includes(item.text) ? 'menu__item--selected' : ''}`"
          @click="selectItem(item)"
        >
          {{ item.text }}
          <div
            v-if="item.secondaryText"
            class="menu__item--secondary"
          >
            {{ item.secondaryText }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="menu-empty-state"
      >
        <div class="menu-empty-state__title">
          {{ emptyStateTitle }}
        </div>
        <div class="menu-empty-state__text">
          {{ emptyStateText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import ChipsInput from './ChipsInput.vue'

type ValueType = string;
type ItemType = {
  text: string,
  secondaryText?: string,
  value?: string
};

const props = withDefaults(defineProps<{
  placeholder?: string,
  allSelector?: string,
  menuItems: Array<ItemType>,
  emptyStateTitle: string,
  emptyStateText: string,
  value?: Array<ValueType>,
}>(), {
  placeholder: '',
  allSelector: '',
});

const emit = defineEmits<{
  (e: "input", value?: Array<ValueType>): void;
  (e: "select", value: string): void;
  (e: "removed", value: string): void;
}>();

const inputValue = ref('');
const filteredItems = computed(() => props.menuItems.filter(mi => mi.text.toLowerCase().startsWith(inputValue.value.toLowerCase())));
const menuOpen = ref(false);

const items = computed({
  get: () => props.value,
  set: (newValue) => emit('input', newValue)
});

function change(value: string) {
  inputValue.value = value
}

function selectItem(item: ItemType) {
  items.value = props.value && (props.value.some(si => si === item.text) ?
    props.value.filter(si => si !== item.text) :
    props.value.concat(item.text));

  menuOpen.value = false;
  inputValue.value = '';
  if (item.value) {
    emit('select', item.value);
  }
}

function selectAll() {
  items.value = props.menuItems.map(mi => mi.text)
  menuOpen.value = false;
  inputValue.value = '';
}

function onClickOutside() {
  menuOpen.value = false;
  inputValue.value = '';
}

function input(itemsProp?: Array<string>) {
  items.value = itemsProp && itemsProp.filter((i: ValueType) => props.menuItems.find(mi => mi.text === i));
}

</script>

<style lang="scss">
@import "../../assets/styles/main";

.chips-select {
  position: relative;
  &__menu {
    box-shadow: 2px 4px 12px rgba(42, 52, 64, 0.08);
    position: absolute;
    padding: 8px 4px;
    width: 100%;
    background: white;
    border: 1px solid $elements;
    border-radius: 4px;
    z-index: 10;
    margin-top: 4px;
    max-height: 204px;
    overflow: auto;
    overflow: overlay;
    &::-webkit-scrollbar {
      width: 12px;
    }

    & {
      scrollbar-width: thin;
      scrollbar-color: $secondaryMedium $primaryWhite;
    }

    &::-webkit-scrollbar-track {
      background: $primaryWhite;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $secondaryMedium;
      border-radius: 8px;
      border: 4px solid $primaryWhite;
      position: absolute;
      right: 10px;
    }
    .menu__item {
      padding: 4px 8px;
      border-radius: 4px;
      color: $primary;
      @include body-2;
      cursor: pointer;
      &--secondary {
        margin-top: 2px;
        @include counter;
        font-weight: 500;
        color: $secondaryMedium;
      }
      &:hover {
        background: $accentClear;
      }
      &--selected {
        &, * {
          color: $primaryWhite;
        }
        background: $accent !important;
      }
    }
  }

  .menu-empty-state {
    margin: 24px auto;
    max-width: 182px;
    &__title {
      @include subtitle-2;
      margin-bottom: 8px;
      color: $primary;
    }
    &__text {
      @include body-3;
      color: $secondary;
    }
    * {
      text-align: center;
    }
  }

  .chips__combobox  .v-input__control{
    min-height: 36px !important;
  }
}
</style>