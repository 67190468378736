<template>
  <div class="verification-code">
    <div class="verification-code__container">
      <input
        v-for="(n, index) in code"
        :id="'input_' + index"
        :key="index"
        v-model="code[index]"
        maxlength="1"
        class="verification-code__input"
        :class="{ 'verification-code__input--error': errorMessage}"
        @input="handleInput"
        @keydown.delete="handleDelete"
        @paste="onPaste"
      >
    </div>
    <div
      v-if="errorMessage"
      class="verification-code__error-text"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';

  const props = withDefaults(
  defineProps<{
    errorMessage?: string;
    codeLength?: number;
  }>(), {
    codeLength: 6,
  }
);
  const code = ref<string[]>(Array(props.codeLength));

  let dataFromPaste: string[] | undefined;

  const emit = defineEmits<{
    (e: "input", value?: string): void;
  }>();


  function handleInput(event: Event) {
    const inputType = (event as InputEvent).inputType;
    let currentActiveElement = event.target as HTMLInputElement;

    if (inputType === "insertText" && (currentActiveElement.nextElementSibling as HTMLElement)){
      (currentActiveElement.nextElementSibling as HTMLElement).focus();
    }

    if (inputType === "insertFromPaste" && dataFromPaste) {
      for (const num of dataFromPaste) {
        const id: number = parseInt(currentActiveElement.id.split("_")[1]);
        currentActiveElement.value = num;
        code.value[id] = num;
        if (currentActiveElement.nextElementSibling) {
          currentActiveElement = currentActiveElement.nextElementSibling as HTMLInputElement;
          if(currentActiveElement.nextElementSibling) {
            (currentActiveElement.nextElementSibling as HTMLElement).focus();
          }
        }
      }
    }

    emit("input", code.value.join(""));
  }

  function handleDelete(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    const currentActiveElement = event.target as HTMLInputElement;
    if (!value && currentActiveElement.previousElementSibling) { 
      (currentActiveElement.previousElementSibling as HTMLElement).focus();
    }
  }

  function onPaste(event: Event) {
    if( !( event instanceof ClipboardEvent) || !event.clipboardData) return;

    dataFromPaste = event.clipboardData.getData("text").trim().split("");
    if(!dataFromPaste) return;
    for (const text of dataFromPaste) {
      if (!text) event.preventDefault();
    }
  }
</script>

<style lang="scss">
@import "../../assets/styles/main";

.verification-code {
  &__container {
    display: flex;
    gap: 12px;
  }
  &__error-text {
    @include body-2;
    color: $negative;
    margin-top: 4px;
  }
  &__input {
    @include headline-3;
    height: 60px;
    width: 60px;
    border: 1px solid $elements;
    border-radius: 4px;
    appearance: textfield;
    -moz-appearance: textfield;
    padding: 8px;
    color: $primaryLight;
    text-align: center;
  }
  &__input--error {
    border: 1px solid $negative;
  }
  &__input:focus {
    outline: none;
    background: $backgrounds;
  }
  &__input::-webkit-outer-spin-button,
  &__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>