<template>
  <v-treeview
    :items="items"
    :search="search"
    :load-children="fetchChildren"
    :active="activeItems"
    :open="openItems"	
    class="tree-view"
    activatable
    item-key="id"
    return-object
    color="accent"
    transition
    expand-icon="$icon_arrow_down"
    loading-icon="$icon_loading"
    @update:open="updateOpenItems"
    @update:active="updateActiveItems"
  >
    <template #label="{ item, active }">
      <div>
        <span>
          <v-icon
            size="20"
            :color="active ? 'accent' : item.isVirtualFolder ? 'secondaryLight' : ''"
          >
            {{ '$icon_folder' }}
          </v-icon>
        </span>
        <tooltip :text="item.disabled && getDisabledText ? getDisabledText(item.name) : item.name">
          <template #content="{on, attrs}">
            <span
              :data-testid="'tree-view-' +item.name"
              v-bind="attrs"
              :class="[
                'tree-view__title',
                {
                  'tree-view__virtual-title': item.isVirtualFolder,
                  'tree-view__active-title': active,
                },
              ]"
              v-on="on"
            > 
              {{ item.name }}
            </span>
          </template>
        </tooltip>
      </div>
    </template>
  </v-treeview>
</template>
  
<script setup lang="ts">
  import tooltip from "../tooltip/Tooltip.vue";

  withDefaults(
    defineProps<{
      items?: object[],
      getDisabledText?: CallableFunction,
      fetchChildren?: CallableFunction,
      search?: string,
      openItems?: object[],
      activeItems?: object[],
    }>(),
    {
      items: () => [],
      getDisabledText: () => { return },
      fetchChildren: () => { return },
      search: '',
      openItems: () => [],
      activeItems: () => [],
    }
  )


  
  const emit = defineEmits<{
    (e: 'updateActiveItems', items:object[]): void;
    (e: 'updateOpenItems', items:object[]): void;
  }>()

  function updateActiveItems(active: object[]) {
    emit('updateActiveItems', active)
  }

  function updateOpenItems(openItems: object[]) {
    emit('updateOpenItems', openItems)
  }

</script>

<style lang="scss" scoped>
  @import "../../assets/styles/main";
  .tree-view { 
    &__title {
      @include body-2;
    }
    &__virtual-title {
      color: $secondaryLight !important;
    }
    &__active-title {
      color: $accent !important;
    }
  }

</style>
  
<style lang="scss">
  @import "../../assets/styles/main";
  .tree-view .v-treeview-node__root::before {
    border-radius: 4px;
  }
  .tree-view .v-treeview-node__toggle{
    color: $primary;
    width: 20px;
    height: 20px;
  }
</style>