<template>
  <v-layout
    row
    :class="`nav-items ${mini ? 'nav-items--small-height nav-items--mini' : ''}`"
  >
    <div
      v-if="vuetify.breakpoint.lgAndUp"
      class="expand-buttton"
      @click.prevent="collapser"
    >
      <tooltip :text="mini ? expandTooltip : collapseTooltip">
        <template #content="{on, attrs}">
          <v-icon
            color="white"
            size="20"
            v-bind="attrs"
            v-on="on"
          >
            {{ mini ? '$icon_arrow_right' : '$icon_arrow_left' }}
          </v-icon>
        </template>
      </tooltip>
    </div>
    <v-flex>
      <v-list
        class="nav-list--padding"
      >
        <v-list-group
          v-for="(item, i) in computedItems"
          :key="i"
          :value="item.active"
          no-action
          class="list-group"
          :class="[{ 'list-group--active': item.active },
                   { 'list-group--disable': item.disable }]"
          @click="() => handleItemClick(i)"
        >
          <template #activator>
            <v-menu
              v-model="mobileMenuToggled"
              tile
              :nudge-right="vuetify.breakpoint.name === 'md' ? 35 : 31"
              :nudge-top="vuetify.breakpoint.name === 'md' ? 14 : 18"
              :close-on-click="mini"
              :open-on-click="false"
              content-class="list-group__menu-content"
            >
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  class="list-group__item d-flex align-center"
                  v-on="on"
                >
                  <tooltip :text="mini ? item.name : ''">
                    <template #content="{on: tooltipOn, attrs: tooltipAttrs}">
                      <v-icon
                        :class="[
                          'nav-icon',
                          {
                            'nav-icon--blue': item.active,
                            'nav-icon--grey': (item.active && mobileMenuToggled && mini),
                            'nav-icon--disable': item.disable
                          }]"
                        :size="(vuetify.breakpoint.lgAndUp && !mini) ? 20 : 24"
                        v-bind="tooltipAttrs"
                        v-on="tooltipOn"
                      >
                        {{ `${item.icon}` }}
                      </v-icon>
                    </template>
                  </tooltip>
                  <tooltipped-text 
                    v-if="vuetify.breakpoint.lgAndUp && !mini"
                    :key="i"
                    :class="[
                      'item__list-title',
                      {
                        'item__list-title--blue': item.active,
                        'item__list-title--default': !item.active,
                        'item__list-title--disable': item.disable,
                      },
                    ]"
                    :text="item.name" 
                    :max-width="168"
                  />
                  <div
                    v-if="item.numberOfItems && item.numberOfItems > 0 && vuetify.breakpoint.lgAndUp && !mini"
                    class="item__number-of-items"
                  >
                    <span>
                      {{ item.numberOfItems }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- This is small popup menu list when menu bar is minimized -->
              <v-list
                v-if="item.active && ( item.subItems.length || !!item.infiniteHandler ) && mini"
                dense
                color="primary"
              >
                <nav-sub-item-list 
                  :sub-items="item.subItems"
                  :parent-index="i"
                  minimized
                  :has-infinite-handler="!!item.infiniteHandler"
                  @subItemClicked="subItemClicked"
                  @handleIntersection="handleIntersection"
                />
              </v-list>
            </v-menu>
          </template>
          <!-- This is the menu list when menu bar is NOT minimized -->
          <template v-if="!mini">
            <nav-sub-item-list 
              :sub-items="item.subItems"
              :parent-index="i"
              :has-infinite-handler="!!item.infiniteHandler"
              @subItemClicked="subItemClicked"
              @handleIntersection="handleIntersection"
            />
          </template>
        </v-list-group>
      </v-list>
    </v-flex>
  </v-layout>
</template>

<script setup lang="ts">
import Tooltip from '../../tooltip/Tooltip.vue';
import TooltippedText from '../../tooltip/TooltippedText/TooltippedText.vue';
import { computed, ref, watch } from 'vue';
import vuetify from '../../../service/useVuetify';
import { IntersectionStateChanger, NavBarItems } from '@/utils/types';
import NavSubItemList from './NavSubItemList.vue';

const props = withDefaults(
  defineProps<{
    mini: boolean,
    expandTooltip?: string,
    collapseTooltip?: string,
    items: NavBarItems,
  }>(),
  {
    expandTooltip: '',
    collapseTooltip: '',
  }
);

const emit = defineEmits<{
  (e: "update:mini", mini: boolean): void;
  (e: "subItemClicked", i: number, subItemIndex?: number): void;
  (e: "infiniteHandler", parentIndex: number, state: IntersectionStateChanger): void;
}>();

const mobileMenuToggled = ref(false);
const computedItems = computed(() => props.items);

watch(
  () => vuetify.value.breakpoint.mdAndDown,
  (isSmallerScreen) => {
    if(isSmallerScreen) emit('update:mini', true);
  }
);

function subItemClicked(parentIndex: number, subItemIndex?: number) {
  emit('subItemClicked', parentIndex, subItemIndex);
}

function handleIntersection(parentIndex: number, state: IntersectionStateChanger) {
  emit('infiniteHandler', parentIndex, state)
}

function collapser() {
  mobileMenuToggled.value = props.mini;
  emit('update:mini', !props.mini);
}

function handleItemClick(i: number) {
  if(computedItems.value[i].active) {
    mobileMenuToggled.value = true;
  }
  emit('subItemClicked', i);
}
</script>

<style lang="scss">
@import "../../../assets/styles/main";

.list-group__menu-content.menuable__content__active {
  border-radius: 4px !important;
  box-shadow: none !important;
  width: 220px;
  background: $primary  !important;

  .v-list {
    max-height: 240px;
    margin: 12px 0;
    overflow-y: auto;
    padding: 0 !important;
    overflow-x: hidden;

    scrollbar-width: thin;
    scrollbar-color: $secondaryMedium $primary;

    &::-webkit-scrollbar {
      border: unset !important;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $primary;
    }

    &::-webkit-scrollbar-thumb {
      border: unset;
      background: $secondaryMedium;
      border-radius: 8px;
      right: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $secondaryMedium;
    }
  }
}

.v-list-group__items {
  max-height: 210px !important;
  margin-right: 4px;

  // overflow overlay doesn't work on firefox or safari, it will use auto
  // supported browsers will overwrite and use overlay
  overflow: auto;
  overflow: overlay;

  scrollbar-width: thin;
  scrollbar-color: $secondaryMedium transparent;

  &::-webkit-scrollbar {
    width: 4px;
    display: none;
    transition: .5s;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $secondaryMedium;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $secondaryMedium;
  }

  .v-list-item {
    min-height: 28px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:hover {
      background: unset !important;
    }

    &:first-child {
      padding-top: 8px;
    }

    &:last-child {
      padding-bottom: 8px;
    }

    &__content {
      padding: 0px 12px 0 44px;
    }

    &__title {
      @include subtitle-2;
    }
  }
}

.nav-items {
  &--small-height {
    height: calc(100vh - 166px);
  }

  overflow: auto;
  overflow: overlay;
  @include flex-center;
  @include zero-margin-padding;

  .flex {
    align-self: baseline;
  }

  scrollbar-width: thin;
  scrollbar-color: $secondaryMedium transparent;

  &__list-item {
    &:hover {
      background: unset !important;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
    display: none;
    transition: .5s;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }

  &::-webkit-scrollbar-track {
    background: $primary;
  }

  &::-webkit-scrollbar-thumb {
    border: unset;
    background: $secondaryMedium;
    border-radius: 8px;
    right: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $secondaryMedium;
  }

  .v-list-item__icon {
    display: none !important;
  }

  .expand-buttton {
    @include width-height (32px, 32px);
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    top: 64px;
    right: -16px;
    background: $primary;
    z-index: 100;
    cursor: pointer;
    transition: opacity .2s ease-in-out;
    pointer-events: none;

    &:hover {
      background: $accent;
    }

    .v-icon {
      @include absolute-center;
      @include width-height (32px, 32px);
    }
  }

  .nav-list--padding {
    padding: 0 12px;

    @media (max-width: $MD) {
      padding: 0 9px;
    }
  }

  .list-group {
    border-radius: 4px;

    &--active {
      background: $primaryMedium;

      @media (max-width: $LG) {
        background: transparent !important;
      }

      .item__number-of-items {
        background-color: $primaryLight  !important;
      }
    }

    &--disable {
      pointer-events: none;
    }

    &:not(:last-child) {
      margin-bottom: 4px !important;

      @media (max-width: $LG) {
        margin-bottom: 12px !important;
      }
    }

    &__item {
      padding: 0 12px;
      flex: 1;
      height: 40px;

      @media (max-width: $LG) {
        padding: unset;
        justify-content: center;
        height: 32px;
      }
    }

    .item__number-of-items {
      @include width-height(20px);
      background-color: $primaryMedium;
      border-radius: 50%;
      position: relative;

      span {
        position: absolute;
        top: 52%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-weight: 700;
        font-size: 10px;
        line-height: 100%;
      }
    }

    .nav-icon {
      color: $secondaryLight !important;
      margin-right: 12px;

      &--blue {
        color: $accent  !important;
      }

      &--grey, &--disable {
        color: $secondary  !important;
      }

    }

    .v-list-group__header {
      padding: 0;
      min-height: 40px !important;
      border-radius: 4px;
      margin: auto;

      @media (max-width: $LG) {
        min-height: 32px !important;
        @include width-height(32px, 32px);
      }

      .item__list-title {
        align-self: center;
        @include subtitle-1;
        flex: 1;

        &:focus {
          color: $accent  !important;
        }

        &--blue {
          color: $accent  !important;
        }

        &--default {
          color: $secondaryLight !important;
        }

        &--disable {
          color: $secondary !important;
        }
      }

      &:hover {

        .item__list-title,
        .nav-icon {
          color: $accent  !important;
        }

        .item__number-of-items {
          background: $accent  !important;
        }
      }
    }
  }

  &--mini {
    .expand-buttton {
      pointer-events: unset;
      opacity: 1;
    }

    .nav-list--padding {
      padding: 0 9px !important;
    }

    .list-group {
      border-radius: 4px;

      &--active {
        background: transparent !important;
      }

      &:not(:last-child) {
        margin-bottom: 12px !important;
      }

      &__item {
        padding: unset;
        justify-content: center;
        height: 32px;
      }

      .nav-icon {
        margin-right: 0;
        @include width-height (24px, 24px);
      }
    }
  }
}
</style>