<template>
  <div class="chips-autocomplete">
    <v-autocomplete
      ref="paginationRef"
      v-model="selectedItems"
      :items="items"
      item-text="title"
      outlined
      multiple
      hide-selected
      return-object
      clearable
      dense
      clear-icon="$icon_close"
      :append-icon="hasError ? '$icon_attention' : ''"
      :placeholder="placeholder"
      attach=".chips-autocomplete__menu"
      :no-data-text="!paginated ? noDataText : ''"
      :filter="filter"
      :style="{
        'max-width': maxWidth ? maxWidth + 'px' : 'auto'
      }"
      :hide-details="hideDetails"
      :rules="rules"
      :search-input.sync="paginationRef.searchText"
      :no-filter="paginated"
      :loading="loading"
    >
      <template
        v-if="paginated"
        #append-item
      >
        <div v-intersect="handleIntersection" />
        <loading-spinner
          v-if="!paginationRef.loaded && !paginationRef.completed"
          is-loading
          :absolute="false"
          :class="['infinite-loader', { 'infinite-loader--margin': !items.length }]"	
        />
      </template>
      <template #selection="data">
        <v-chip
          color="$primary"
          v-bind="data.attrs"
          :input-value="data.selected"
          small
          close
          close-icon="$icon_close"
          @click:close="remove(data.item)"
        >
          {{ data.item.title }}
        </v-chip>
      </template>
      <template #prepend-item>
        <v-list-item
          :ripple="false"
          @click="selectAll"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ selectAllText }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #item="{item, on}">
        <v-list-item
          :ripple="false"
          v-on="on"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #no-data>
        <v-list-item
          :ripple="false"
          disabled
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ noDataText }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <div class="chips-autocomplete__menu" />
  </div>
</template>

<script lang="ts">
  export type AutoCompleteValue = { text: string, value: string | number } | string;
</script>

<script lang="ts" setup>
import {computed, ref, watch} from "vue";
import LoadingSpinner from "../../components/loaders/LoadingSpinner.vue";
import { IntersectionPaginationOption, IntersectionStateChanger } from "@/utils/types";


const paginationRef = ref<IntersectionPaginationOption>({
  loaded: false,
  completed: false,
  searchText: undefined, 
  bounceTimer: undefined
})
const hasError = computed(()=> paginationRef.value?.hasError && paginationRef.value?.shouldValidate);

type Item = {
  title: string
  subtitle: string
  [key: string]: unknown
}

const props = withDefaults(defineProps<{
    value: Item[]
    items: Item[]
    noDataText: string
    paginated?: boolean
    loading?: boolean
    selectAllText: string
    placeholder: string
    maxWidth?: number
    idKey?: string
    hideDetails?: boolean
    rules?: Array<string | boolean | ((value: object) => boolean | string)>
  }>(), {
    idKey: 'subtitle',
    maxWidth: 0,
    hideDetails: false,
    paginated: false,
    rules: () => []
  }
)

const emit = defineEmits<{
  (e: "input", value: Item[]): void;
  (e: "search", value: { searchText: string | undefined | null, state: IntersectionStateChanger }): void;
  (e: "infinite", value: IntersectionStateChanger): void;
}>();

const selectedItems = computed({
  get: () => {
    return props.value;
  },
  set: (value) => {
    emit('input', value)
  }
})

function handleIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver, isIntersecting: boolean) {
  if(isIntersecting) {
    if(paginationRef.value.completed) return;
    paginationRef.value.loaded = false;
    emit('infinite', state);
  }
}

watch(
  () => paginationRef.value.searchText,
  (newValue, oldValue) => {
    if(!props.paginated) return;
    if(newValue === oldValue) return;
    state.reset();
    
    if(paginationRef.value.searchText?.length && paginationRef.value.searchText === props.value.text) {
      return;
    }
    // debounce for the search
    clearTimeout(paginationRef.value.bounceTimer);
    paginationRef.value.bounceTimer = setTimeout(() => {
      emit('search', {searchText: paginationRef.value.searchText, state});
    }, 500);
  }
)

const state: IntersectionStateChanger = {
  loaded: () => {
    paginationRef.value.loaded = true;
  },
  completed: () => {
    paginationRef.value.completed = true;
  },
  reset: () => {
    paginationRef.value.loaded = false;
    paginationRef.value.completed = false;
  }
};

const remove = (removeItem: Item) => {
  const index = props.value.findIndex((item) => item[props.idKey] === removeItem[props.idKey]);
  const valueCopy = structuredClone(props.value);
  if (index >= 0) {
    valueCopy.splice(index, 1);
  }
  selectedItems.value = valueCopy;
};

const selectAll = () => {
  emit('input', props.value.length === props.items.length ? [] : props.items)
}

const filter = (item: Item, queryText: string) => {
  const matchesTitle = item.title.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
  const matchesSubtitle = item.subtitle.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
  return matchesTitle || matchesSubtitle;
}

</script>
<style lang="scss" scoped>
@import "../../assets/styles/main";

.chips-autocomplete {
  position: relative;
}

:deep {
  // MENU
  .v-menu__content {
    @include eewc-scrollbar;
    border-radius: 4px;
    padding: 8px 4px;
    margin-right: 5px;
    background: $primaryWhite;

    .v-list {
      padding: 0;

      &-item {
        $titleHeight: 20px;
        $subtitleHeight: 18px;
        $paddingY: 8px;

        padding: 0 !important;
        border-radius: 4px;
        min-height: $titleHeight + $subtitleHeight + $paddingY;

        &__content {
          padding: 4px 8px;
        }

        &__title {
          @include body-2;
          margin: 0;
        }

        &__subtitle {
          @include body-3;
          color: $secondaryMedium
        }

        // MODIFICATIONS
        &:hover,&--highlighted {
          background: $accentClear;
        }
        
        &--highlighted:before {
          opacity: 0;
        }

        &:active {
          background: $accent;

          .v-list-item__title {
            color: $primaryWhite !important;
          }

          .v-list-item__subtitle {
            color: $primaryWhite;
          }
        }

        &--disabled {
          color: $primaryLight;
        }
      }
    }
  }

  // INPUT
  .v-input {
    &__slot {
      $chips-height: 24px;
      $chips-gap: 4px;
      $menu-max-height: ($chips-height * 3) + ($chips-gap * 2);

      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 36px !important;
      padding: 6px 12px !important;

      fieldset {
        color: $elements !important;
        border: 1px solid;
        background: $backgrounds;
      }

      .v-select__slot {
        overflow-y: auto;
        overflow-x: hidden;
        align-items: flex-start;
        max-height: $menu-max-height !important;
        @include eewc-scrollbar;

        .v-input--dense .v-select__selections,
        .v-select__selections {
          gap: 4px;
          padding: 0 !important;

          input {
            padding: 2px 0 !important;
            @include body-2;

            &::placeholder {
              color: $secondaryMedium !important;
            }
          }
        }
      }
    }

    &__append-inner {
      margin: 0 !important;
      position: sticky;
      top: 0;

      .v-icon {
        svg{
          color: $secondaryMedium !important;
          caret-color: $secondaryMedium !important;
        }
        &:after {
          transform: scale(1);
        }
      }
    }

    // MODIFICATIONS
    &--is-focused {
      fieldset {
        border: 1px solid $accent !important;
      }
    }

    // ERROR STATE
    .v-text-field__details {
      position: absolute;
      bottom: -24px;
      left: -12px;

      .v-messages__message {
        @include body-2;
      }
    }

    &.error--text {
      fieldset {
        border: 1px solid $negative !important;
      }
    }
  }

  .v-autocomplete.error--text .v-input__append-inner .v-icon svg {
    color: $negative !important;
    caret-color: $negative !important;
  }

  // CHIPS
  .v-chip {
    margin: 0 !important;
    color: $primaryWhite !important;
    background-color: $primary !important;
    @include body-2;
    .v-icon {
      $size: 20px;
      margin-left: 2px;
      
      font-size: $size !important;
      width: $size !important;
      height: $size !important;
      max-width: $size !important;
      max-height: $size !important;
      svg {
        font-size: $size !important;
        width: $size !important;
        height: $size !important;
      }
    } 
  }

  .infinite-loader {
    text-align: center;

    &--margin {
      margin-top: -30px;
    }
  }
}
</style>
