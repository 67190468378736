<template>
  <div
    class="tooltip-container"
    :style="{ maxWidth: maxWidthValue }"
  >
    <v-tooltip
      v-if="show"
      bottom
      color="primary"
      open-delay="200"
      close-delay="200"
    >
      <template #activator="{ on, attrs }">
        <div
          ref="textRef"
          v-bind="attrs"
          class="text-content"
          v-on="on"
          @mouseover="mouseover"
        >
          {{ text }}
        </div>
      </template>
      <span>
        {{ text }}
      </span>
    </v-tooltip>
    <div
      v-else
      ref="textRef"
      class="text-content"
      @mouseover="mouseover"
    >
      {{ text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';

const props = defineProps<{
  text?: string;
  maxWidth?: number | string;
}>();

const show = ref(false);
const textRef = ref();

const maxWidthValue = computed(() => props.maxWidth ? ((typeof props.maxWidth === 'number') ? props.maxWidth + 'px' : props.maxWidth) : undefined);

onMounted(
  async () => {
    await nextTick(() => {
      checkRange();
    })
  }
)

function checkRange() {
  const el = textRef.value;
  if (el) {
    const range = new Range();
    range.selectNodeContents(el);

    const rangeRect = range.getBoundingClientRect();
    const elRect = el.getBoundingClientRect();

    // add .5px bcz when the range width shoud be exactly the same as that of the rect,
    // it is slightly smaller, so the tooltip is shown where not yet needed
    show.value = rangeRect.width > (elRect.width + .5);
  }
}

function mouseover() {
  checkRange();
}

defineExpose({ show });
</script>

<style lang="scss">
.tooltip-container {
  min-width: 0;
}

.text-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-tooltip__content {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  padding: 6px 8px;
  opacity: 1 !important;
}
</style>
