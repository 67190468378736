<template>
  <div :class="`sortable-tree-table ${isDragAreaExpanded ? 'sortable-tree-table--expand-area' : ''}`">
    <div
      class="header"
      :style="{ gridTemplateColumns: `repeat(${headers.length - (containsActions ? 1 : 0)}, 1fr) ${containsActions ? '48px' : ''}` }"
    >
      <div
        v-for="header in headers"
        :key="header.text"
        :class="['header__item', header.justify && 'justify-' + header.justify]"
      >
        {{ header.text }}
      </div>
      <div
        :class="['header-selector',{'header-selector--block': selectedRows.length > 0}]" 
      >
        <check-box 
          v-if="selectable"
          :value="list.every(item => selectedRows.includes(item.id)) && (selectedRows.length > 0)"
          :indeterminate="!list.every(item => selectedRows.includes(item.id)) && list.some(td => selectedRows.includes(td.id))"
          @change="selectAll"
        />
      </div>
    </div>
    <nested-draggable
      :items="list"
      :on-change="onChange"
      :on-move="onMove"
      :on-start="onStart"
      :contains-actions="containsActions"
      :dropzone-item="dropzoneItem"
      :is-drag-area-expanded="isDragAreaExpanded"
      :headers-length="headers.length"
      :disable-drag="disableDrag"
      :selected-rows="selectedRows"
      :selectable="selectable"
      @row-select="(value) => emit('row-select', value)"
    >
      <template #row="slotData">
        <slot
          name="row"
          v-bind="slotData || {}"
        />
      </template>
    </nested-draggable>
    <!-- For empty state ui -->
    <slot
      v-if="!list.length"
      name="noData"
    />
  </div>
</template>
<script setup lang="ts">
import { calculateDepth } from '../../utils/helpers';
import { computed, ref, watchEffect } from "vue";
import nestedDraggable from "./DraggableRow.vue";
import { TableItem } from './types';
import { MoveEvent } from 'vuedraggable';
import CheckBox from '../../components/selection-controls/CheckBox.vue'

type TableHeader = {
  text: string;
  justify: string;
}

const props = withDefaults(defineProps<{
  headers: TableHeader[],
  value: TableItem[],
  disableDrag?: boolean,
  selectable?: boolean,
  selectedRows?: (string| number)[],
}>(), {
  disableDrag: false,
  selectable: false,
  selectedRows: () => [],
});

const list = computed<TableItem[]>(() => props.value);
const dropzoneItem = ref<string>('');
const isDragAreaExpanded = ref(false);
const containsActions = computed(() => props.headers.some(head => head.text.length === 0));

const emit = defineEmits<{
  (e: "input", value: TableItem[]): void;
  (e: 'row-select', item: number | (string | number)[] | string): void;
}>();

// since the row component is recursive (it renders itself),
// we have put these event in this component
function onStart() {
  isDragAreaExpanded.value = true;
}

function onChange() {
  if(dropzoneItem.value) {
    dropzoneItem.value = '';
  }
  isDragAreaExpanded.value = false;
  list.value.forEach(calculateDepth(0));
  emit('input', list.value);
}

function selectAll(value: boolean) {
  emit('row-select', value ? list.value.map(item => item.id) : []);
}

function onMove(event: MoveEvent<TableItem>) {
  const index = (event.to.previousElementSibling as HTMLDivElement).dataset.index;
  dropzoneItem.value = index ? (index as string) : '';
}

watchEffect(
  () => {
    list.value.forEach(calculateDepth(0));
    emit('input', list.value);
  }
)

</script>
<style lang="scss">
  @import "../../assets/styles/main";
  .sortable-tree-table {
    border: 1px solid $elements;
    &--expand-area {
      .drag-area {
        min-height: 30px;
        padding-top: 30px;
        margin-top: -30px;
        position: relative;
        z-index: 3;
      }
    }
    .header {
      display: grid;
      align-items: center;
      height: 44px;
      background: $backgrounds;
      border-bottom: 1px solid $elements;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: $secondary;
      &__item {
        padding-left: 12px;
        display: flex;
        align-items: center;
        @include subtitle-2;
        &:first-child {
          padding-left: 32px;
        }
        &:last-child {
          padding-right: 12px;
        }
        font-size: 14px;
      }

      &:hover {
        .header-selector {
          display: block;
        }
      }
      &-selector {
        display: none;
        position: absolute;
        margin-left: 8px;

        .v-input--selection-controls__input {
          height: 20px !important;
          width: 20px !important;
        }

        .v-icon, svg {
          height: 12px !important;
          width: 12px !important;
        }

        &--block {
          display: block;
        }
      }
    }
  }
</style>