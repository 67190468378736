<template>
  <div class="donut-container">
    <!-- v-if doesn't work -->
    <GChart
      ref="donutChart"
      class="donut-chart"
      :class="`donut-chart ${((accumulatoryLength > 0) && !loading) ? 'donut-chart--show' : '' }`"
      type="PieChart"
      :data="data"
      :options="options"
      @ready="onChartReady"
    />
    <div :class="`donut-chart__empty-state ${((accumulatoryLength === 0) || loading) ? 'donut-chart__empty-state--show' : '' }`">
      <div
        v-if="(accumulatoryLength === 0) && !loading"
        class="empty-state__ring"
      />
      <v-skeleton-loader
        v-else
        class="empty-state__ring empty-state__ring--skeleton"
        type="image"
      />
    </div>
    <div
      v-if="of && highlight && !loading"
      class="donut-chart__highlight"
    >
      <div>
        {{ highlightLength }} {{ of }} {{ accumulatoryLength }}
      </div>
      <span>
        {{ highlight }}
      </span>
    </div>
    <div
      v-if="chart"
      class="donut-chart__legend"
    >
      <div v-if="loading">
        <skeleton
          v-for="item in 4"
          :key="item"
          class="skeleton-item"
          type="text"
        />
      </div>
      <template v-else>
        <div
          v-for="(item, i) in data.slice(1)"
          :key="i"
          :class="`legend-item ${hoverLegendItems.includes(i) ? 'hovered' : ''}`"
          @mouseover="() => hover(i)"
          @mouseleave="leave"
        >
          <span :style="`color:${options.colors[i]}`">
            {{ item[1] }}
          </span>
          {{ item[0] }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import { GChart } from 'vue-google-charts/legacy'
import Skeleton from './loaders/Skeleton.vue'

type ChartDataItem = string | number;

type ChartData = ChartDataItem[]

const props = defineProps<{
  chartData: ChartData[],
  colors: Array<string>,
  of?: string,
  highlight?: string,
  loading?: boolean,
}>();

const emit = defineEmits<{
  chartReady: () => void
}>();

const donutChart = ref(null);

const chart = ref<unknown>(null);
const hoverLegendItems = ref<number[]>([]);
const data = computed(() => [['', ''] as ChartData].concat(props.chartData));
const accumulatoryLength = computed(() => data.value.slice(1).reduce((accumulator: number, current: ChartData) => accumulator + (current[1] as number), 0));
const highlightLength = computed(() => {
  const item = data.value.find(item => item[0] as string === props.highlight as string);
  return item ? item[1] || 0 : 0;
});
const options = reactive({
  width: 150,
  height: 150,
  pieHole: 0.6,
  colors: computed(() => props.colors),
  tooltip: {
    isHtml: true,
    position: 'bottom',
    text: 'value'
  },
  legend: {
    position: 'none',
    alignment: 'center',
    maxLines: 1
  }
})

function onChartReady(eventChart: unknown, google: unknown) {
  chart.value = eventChart;
  google.visualization.events.addListener(eventChart, 'onmouseover', function(e: unknown) {
    hoverLegendItems.value = hoverLegendItems.value.concat(e.row);
  });

  google.visualization.events.addListener(eventChart, 'onmouseout', function(e: unknown) {
    hoverLegendItems.value = hoverLegendItems.value.filter(item => item !== e.row);
  });

  google.visualization.events.addListener(eventChart, 'select', selectHandler);
  emit('chartReady');
}

function selectHandler() {  
  chart.value.setSelection([]);
}

function hover(index: number) {
  // google has no setter for hover, only for selection (which is click)
  // so we're applying the hover style but for selection.
  if(accumulatoryLength.value && chart) {
    chart.value.setSelection([{ column: null, row: index }]);
    const element = donutChart.value?.$el?.querySelector('svg g path:not(:first-child), ellipse');
    if (element) {
      element.style.strokeWidth = '10px';
      element.style.strokeOpacity = '0.35';
      element.style.transform = 'scale(96%) translate(2%, 2%)';
    }
  }
}

function leave() {
  if(accumulatoryLength.value && chart) {
    chart.value.setSelection([]);
  }
}

</script>

<style lang="scss">
@import "../assets/styles/main";

.donut-container {
  position: relative;
  .donut-chart {
    width: fit-content;
    margin: -30px;
    margin-bottom: -28px;
    display: none;
    &--show {
      display: block;
    }
    g {
      cursor: pointer;
      stroke-width: 3px;
      text {
        display: none !important;
      }
    }
    
    &__empty-state {
      height: 150px;
      width: 150px;
      margin: -30px;
      margin-bottom: -28px;
      position: relative;
      display: none;
      &--show {
        display: block;
      }
      .empty-state__ring {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 88px;
        width: 88px;
        border-radius: 50%;
        border: 18px solid $secondaryLight;

        &--skeleton {
          border: unset;
          div {
            height: 88px;
            width: 88px;
            border-radius: 50%;
            &:before {
              content: '';
              background: white;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 54px;
              width: 54px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  
    rect {
      fill: transparent;
    }
  
    &__highlight {
      position: absolute;
      top: 65px;
      left: 22px;
      width: 50px;
      @include counter;
      text-align: center;
      color: $primary;
      span {
        font-weight: 400;
        font-size: 8px;
      }
    }
  
    &__legend {
      position: relative;
      z-index: 1;
      display: inline-grid;
      gap: 4px;
      margin-top: 14px;
      .skeleton-item {
        width: 105px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      .legend-item {
        cursor: pointer;
        margin: 0;
        height: 20px;
        border-radius: 4px;
        align-items: center;
        padding: 2px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $primary;
        padding-right: 4px;
        span {
          width: 23px;
          text-align: center;
          margin-right: 2px;
          font-weight: 500;
        }
  
        &:hover, &.hovered {
          background: $backgrounds;
        }
      }
    }
  
    .google-visualization-tooltip {
      pointer-events: none;
      height: unset;
      background: $primary;
      border: unset;
      padding: 0 !important;
      border-radius: 4px;
      span {
        color: $elements !important;
        @include tooltips;
        font-size: 10px !important;
        font-weight: 400 !important;
      }
      ul {
        margin: 0 !important;
        padding: 6px 8px !important;
        display: flex;
      }
      li {
        margin: 0 !important;
        padding: 0;
        &:not(:last-child) {
          margin-right: 3px !important;
        }
      }
    }
  }

  svg g path:not(:first-child), ellipse {
    stroke-width: 10px;
    stroke-opacity: 0.35;
    transform: scale(96%) translate(2%, 2%);
  }
}
</style>
