<template>
  <v-expansion-panels
    v-model="openPanels"
    accordion
    :max="maxSelections"
    :multiple="shouldAllowMultipleSelections"
  >
    <slot />
  </v-expansion-panels>
</template>

<script setup lang="ts">
import { defineProps, computed, defineEmits } from "vue";

/**
 * Types
 */

type Panel = number;

type Panels = Array<Panel>;

type PanelOrPanels = Panel | Panels;

type Props = {
  maxSelections?: number | string;
  shouldAllowMultipleSelections?: boolean;
  value?: PanelOrPanels;
};

/**
 * Component
 */

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "input", value?: PanelOrPanels): void;
}>();

const openPanels = computed({
  get: () => props.value,
  set: (newPanels?: PanelOrPanels) => emit("input", newPanels),
});
</script>
