<template>
  <v-footer
    app
    height="auto"
    class="footer px-6 py-4"
    fixed
  >
    <v-row>
      <p
        v-for="item in dataFooter"
        :key="item.title"
        class="px-3 ma-0"
      >
        <a
          target="_blank"
          class="footer__title"
          :href="item.link"
        >
          {{ item.title }}
        </a>
      </p>
    </v-row>
    <span class="footer__company-name">
      &copy; {{ companyName }} {{ year }}
    </span>
  </v-footer>
</template>

<script setup lang="ts">
import { ref } from 'vue';

withDefaults(
  defineProps<{
    dataFooter?: { title: string, link: string }[]
    companyName?: string,
  }>(),
  {
    companyName: "Eagle Eye Networks",
  }
)

const year = ref(new Date().getFullYear());
</script>


<style lang="scss" scoped>
@import "../../../assets/styles/main";

.footer {
  @include flex-center;
  width: 100%;
  background-color: $primary  !important;
  color: $elements  !important;
  z-index: 1;
  bottom: 0;

  &__title {
    @include body-2;
    color: $elements  !important;
    text-decoration: none;

    @media (min-width: $SM) {
      font-size: 14px !important;
    }

    @media (min-width: $LG) {
      font-size: 16px !important;
    }
  }

  &__company-name {
    @include subtitle-1;
    color: $secondaryMedium  !important;
    user-select: none;

    @media (min-width: $SM) {
      font-size: 14px !important;
    }

    @media (min-width: $LG) {
      font-size: 16px !important;
    }
  }
}
</style>
