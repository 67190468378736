<template>
  <div
    :class="[
      'eewc-switch-button',
      size && ('eewc-switch-button--' + size)
    ]"
  >
    <label class="eewc-switch-button__label">
      <input
        v-model="checked"
        :value="value"
        type="checkbox"
        class="eewc-switch-button__input"
        :disabled="disabled"
        :switchText="switchText"
      >

      <div class="eewc-switch-button__track">
        <span class="eewc-switch-button__thumb" />
      </div>

      <p class="eewc-switch-button__text">
        {{ switchText }}
      </p>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const emit = defineEmits<{(e: 'input', value: boolean): void;}>();

const props = defineProps<{
  value: boolean
  disabled?: boolean
  switchText?: string
  size?: string
}>()

const checked = computed({
  get() {
    return props.value
  },
  set(val: boolean) {
    emit("input", val)
  }
})

</script>

<style lang="scss">
@import "../../assets/styles/main.scss";

.eewc-switch-button {
  $self: &;
  position: relative;

  &__input {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    opacity: 0;
  }

  &__label {
    display: inline-block;
  }

  &__track {
    @include width-height(32px, 20px);
    display: flex;
    z-index: 1;
    top: 5%;
    background-color: $secondaryLight;
    opacity: 1;
    border-radius: 60px;
    transition: box-shadow 0.2s, background 0.2s, width 0.2s, height 0.2s;
    cursor: pointer;

    //track states
    &:hover {
      box-shadow: 1px 1px 1px rgba(33, 42, 52, 0.32);
    }
  }

  &__thumb {
    @include width-height(16px);
    background: white;
    border-radius: 50%;
    transform: translate(2px, 2px);
    transition: 0.2s;
    cursor: pointer;
  }

  &__text {
    position: absolute;
    left: 60px;
    top: 5%;
    @include subtitle-2;
  }
  //enabled and checked
  &__input {
    &:active {
      + #{$self}__track {
        background-color: $secondaryMedium;
      }
    }

    &:checked {
      + #{$self}__track {
        background-position: top;
        background-color: $accent;

        &:hover {
          background-color: $accent;
        }

        #{$self}__thumb {
          transform: translate(14px, 2px);
          background-color: white;
        }
      }

      &:active {
        + #{$self}__track {
          background-color: $accentDark;
        }
      }
    }
    &:focus-visible {
      + #{$self}__track {
        box-shadow: none;
        outline-offset: 2px;
        outline: 1px solid black;
      }
    }

    //disable states

    //disabled and unchecked
    &:disabled {
      + #{$self}__track {
        box-shadow: none!important;
        background-color: $secondaryLight!important;
        cursor:default;
      }

      + #{$self}__track #{$self}__thumb {
        background-color: $elements!important;
        cursor: default;
      }
    }

    //disabled and checked
    &:disabled:checked {
      + #{$self}__track {
        background-color: $accent!important;
      }

      + #{$self}__track #{$self}__thumb {
        background-color: $secondaryLight!important;;
      }
    }
  }

  // we need to check state for label
  &__label {
    &:active {
      #{$self}__input {
        + #{$self}__track {
          background-color: $secondaryMedium;
        }

        &:checked {
          + #{$self}__track {
            background-color: $accentDark;
          }
        }
      }
    }
  }

  //Large switcher
  &--large {
    #{$self}__track {
      @include width-height(40px, 24px);
    }
    #{$self}__thumb {
      @include width-height(20px);
    }

    #{$self}__input {
      &:checked {
        & + #{$self}__track #{$self}__thumb {
          transform: translate(18px, 2px);
        }
      }
    }
  }
}
</style>
