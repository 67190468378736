<template>
  <div
    v-click-outside="hideDropdown"
    class="dropdown-range-input"
    @keyup.enter="hideDropdown"
  >
    <button
      class="dropdown-range-input__select"
      :class="{
        'dropdown-range-input__select--active': isDropdownOpen,
      }"
      @click="isDropdownOpen = !isDropdownOpen"
    >
      <span>{{ valueLabel }} </span>
      <v-icon
        :size="24"
        class="ml-auto"
        color="secondaryMedium"
      >
        $icon_arrow_down
      </v-icon>
    </button>
    <div
      v-if="isDropdownOpen"
      class="dropdown-range-input__content d-flex align-center py-3 mt-1"
    >
      <div
        class="dropdown-range-input__box pa-0 d-flex justify-space-between align-center"
        :class="{ 'dropdown-range-input__box--error': !isMinimumValueNumber }"
      >
        <input
          v-model="minimumValue"
          class="input-container-box py-2 ps-3 pr-2 ma-0"
          :placeholder="minValueLabelString"
        >
        <v-icon
          v-show="!isMinimumValueNumber"
          :size="24"
          color="negative"
          class="attention-icon ml-auto"
        >
          $icon_attention
        </v-icon>
      </div>
      -
      <div
        class="dropdown-range-input__box d-flex justify-space-between align-center"
        :class="{ 'dropdown-range-input__box--error': !isMaximumValueNumber }"
      >
        <input
          v-model="maximumValue"
          class="input-container-box py-2 ps-3 pr-2 ma-0"
          :placeholder="maxValueLabelString"
        >
        <v-icon
          v-show="!isMaximumValueNumber"
          :size="24"
          color="negative"
          class="attention-icon ml-auto"
        >
          $icon_attention
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

/**
 * Types
 */
type RangeValue = string | undefined;

type Range = RangeValue[];
/**
 * Utils
 */

const isValueNumber = (value: string | undefined) => {
  if (value) {
    if (Number(value) === 0) {
      return true;
    }

    if (!Number(value)) {
      return false;
    }
  }

  return true;
};

/**
 * Components
 */

const emit = defineEmits<{
  (e: "input", value: Range): void;
}>();

const props = defineProps<{
  value: Range;
  rangeUnit: string;
  labelString: string;
  minValueLabelString: string;
  maxValueLabelString: string;
}>();

const isDropdownOpen = ref(false);
const minimumValue = ref<string>();
const maximumValue = ref<string>();

const valueLabel = computed(() => {
  const minValue = props.value[0];
  const maxValue = props.value[1];

  if (!isDropdownOpen.value) {
    if (!!minValue && !!maxValue && Number(minValue) < Number(maxValue)) {
      return `${minValue} - ${maxValue} ${props.rangeUnit}`;
    }
  }

  return props.labelString;
});

const isMinimumValueNumber = computed(() => {
  return isValueNumber(props.value[0]);
});

const isMaximumValueNumber = computed(() => {
  return isValueNumber(props.value[1]);
});

const range = computed(() => {
  return [minimumValue.value, maximumValue.value];
});

const rangeValue = computed(() => props.value);

const hideDropdown = () => {
  isDropdownOpen.value = false;
};

watch(range, (newValue) => {
  emit("input", newValue);
});

watch(rangeValue, (newValue) => {
  if (!newValue.length) {
    minimumValue.value = undefined;
    maximumValue.value = undefined;
  }
});
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/main.scss";

.dropdown-range-input {
  position: relative;
  @include body-2;

  &__select {
    min-height: 36px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 12px;
    height: 36px;
    border: 1px solid $elements;
    border-radius: 4px;
    background-color: $backgrounds;

    &--active {
      border-color: $accent;
      .v-icon {
        transform: rotate(180deg);
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__content {
    position: absolute;
    z-index: 10;
    width: 100%;
    background-color: $primaryWhite;
    border: 1px solid $elements;
    border-radius: 4px;
  }

  &__box {
    width: 100%;
    margin-inline: 8px;
    background-color: $backgrounds;
    border-radius: 4px;
    border: 1px solid $elements;

    &::placeholder {
      color: $secondaryMedium;
    }

    &:focus {
      outline: none;
      border-color: none;
    }

    &:focus-within {
      border: 1px solid $accent;

      & > .attention-icon {
        display: none;
      }
    }

    &--error {
      border: 1px solid $negative;
    }
  }
}

.input-container-box {
  width: 100%;
  margin-inline: 8px;
  background-color: $backgrounds;
  border-radius: 4px;

  &::placeholder {
    color: $secondaryMedium;
  }

  &:focus {
    outline: none;
    border-color: none;
  }
}
</style>
