<template>
  <div
    class="logo"
    :style="
      vuetify.breakpoint.mdAndDown || mini
        ? logoMarginSM
        : '' || vuetify.breakpoint.mdOnly
          ? logoMarginMD
          : '' || vuetify.breakpoint.lgOnly
            ? logoMarginLG
            : '' || vuetify.breakpoint.xlOnly
              ? logoMarginXL
              : ''
    "
  >
    <div
      v-if="!loading"
    >
      <v-img
        v-if="vuetify.breakpoint.mdAndDown || mini"
        contain
        :max-width="logoSmallWidth"
        :max-height="logoSmallHeight"
        :src="errorDownloadingSmallImage ? altLogoSmall : logoSmall"
        @error="errorDownloadingSmallImage = true"
      />
      <v-img
        v-else
        contain
        :max-width="logoBigWidth"
        :max-height="logoBigHeight"
        :src="errorDownloadingBigImage ? altLogoBig : logoBig"
        @error="errorDownloadingBigImage = true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import vuetify from "../../../service/useVuetify";

const errorDownloadingBigImage = ref(false);
const errorDownloadingSmallImage = ref(false);

withDefaults(
  defineProps<{
    mini?: boolean;
    loading?:boolean;
    logoSmall: string;
    logoSmallWidth?: string;
    logoSmallHeight?: string;
    logoBig: string;
    logoBigWidth?: string;
    logoBigHeight?: string;
    logoMarginSM?: string;
    logoMarginMD?: string;
    logoMarginLG?: string;
    logoMarginXL?: string;
    altLogoSmall?: string;
    altLogoBig?: string;
  }>(),
  {
    logoSmallWidth: "40px",
    logoSmallHeight: "40px",
    logoBigWidth: "120px",
    logoBigHeight: "44px",
    logoMarginSM: "margin: 12px 4px",
    logoMarginMD: "margin: 12px 8px",
    logoMarginLG: "margin: 10px 56px 21px 38px",
    logoMarginXL: "margin: 10px 76px 21px 59px",
  }
);
</script>
<style lang="scss" scoped>
.logo {
  cursor: pointer;
}
</style>
