<template>
  <v-dialog
    v-model="showDialogViewModel"
    width="360px"
  >
    <v-card
      width="360px"
      min-height="172px"
      class="d-flex flex-column"
    >
      <v-card-title class="subtitle-2 card__title-text">
        <v-icon
          :style="iconBackground"
          :color="iconColor"
          class="card__icon--background mr-3"
        >
          {{ icon }}
        </v-icon>
        <span
          ref="cardTitleText"
          class="card__title-text__content"
        >
          <tooltipped-text
            v-if="displayTooltip"
            :text="titleSlotContent"
          />
          <slot
            v-if="!displayTooltip"
            name="title"
          />
        </span>
      </v-card-title>
      <v-card-text class="body-2 card__text-padding flex-grow-1">
        <slot name="body" />
      </v-card-text>
      <v-card-actions class="float-right card__actions-padding align-self-end">
        <slot name="buttonleft" />
        <div class="pl-3">
          <slot name="buttonright" />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import TooltippedText from '../tooltip/TooltippedText/TooltippedText.vue';

const cardTitleText = ref<HTMLElement | null>(null);
const displayTooltip = ref(false);
const titleSlotContent = ref('');

const setTitleSlotContent = () => {
  const cardTitleTextValue = cardTitleText.value;
  if (cardTitleTextValue) {
    if (
      cardTitleTextValue.scrollHeight > cardTitleTextValue.clientHeight ||
      cardTitleTextValue.scrollWidth > cardTitleTextValue.clientWidth
    ) {
      displayTooltip.value = true;
    } else displayTooltip.value = false;
    titleSlotContent.value = cardTitleTextValue.textContent?.trim() || '';
  }
};

onMounted(setTitleSlotContent);

const props = withDefaults(
  defineProps<{
    showDialog?: boolean;
    icon?: string;
    iconColor?: string;
  }>(),
  {
    icon: '$icon_delete',
    iconColor: 'negative',
  }
);

const emit = defineEmits<{
  (e: 'closeDialog'): void;
}>();

const iconBackground = computed(() => {
  return { 'background-color': `var(--v-${props.iconColor}Clear-base)` };
});
const showDialogViewModel = computed({
  get: () => props.showDialog,
  set: (value) => {
    if (!value) emit('closeDialog');
  },
});
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';

.card__text-padding {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.card__title-text {
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding: 22px 20px 14px !important;
  color: $primary;
  white-space: nowrap;
}

.card__title-text__content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show before ellipsis */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.card__actions-padding {
  padding: 0 20px 20px !important;
}
.card__icon--background {
  border-radius: 4px;
}
</style>
