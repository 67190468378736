<template>
  <div>
    <v-text-field
      v-model="selectedItem"
      :type="type"
      dense
      height="36px"
      class="text-field"
      :rules="[...rules]"
      :disabled="disabled"
      :class="[
        { 'eewc-text-field__disabled': disabled },
        { 'eewc-text-field--error': error },
      ]"
      single-line
      :append-icon="error ? '$icon_attention' : rightIcon"
      :prepend-inner-icon="leftIcon"
      :error-messages="errorMessages"
      :label="label"
      outlined
      :clearable="clearable"
      clear-icon="$icon_close"
      :readonly="readonly"
      v-bind="$attrs"
      :hide-details="hideDetails"
      @update:error="onError"
      @focus="$emit('focus')"
      @blur="determineInitialErrorState"
      @click:append="$emit('clickAppend')"
      @click:clear="$emit('clickClear')"
    >
      <template #append>
        <slot name="append" />
      </template>
    </v-text-field>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

type ValueType = string | number;

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    value?: ValueType;
    label?: string;
    rules?: Array<string | boolean | ((value: any) => boolean | string)>;
    leftIcon?: string;
    clearable?: boolean;
    rightIcon?: string;
    readonly?: boolean;
    type?: string;
    errorMessages?: string | string[];
    hideDetails?: boolean;
  }>(), {
    label: 'Name',
    type: 'text',
    hideDetails: false,
    rules: () => []
  }
);

// ensure correct error state when user doesn't input anything
const determineInitialErrorState = () => {
  error.value = props.rules.some((rule) => {
    if (typeof rule === 'function') {
      return typeof rule(selectedItem.value) === 'string';
    } else {
      return typeof rule === 'string' || (typeof rule === 'boolean' && rule);
    }
  });
}

const error = ref(false);

const onError = (value: boolean) => {
  error.value = value;
}

const emit = defineEmits<{
  (e: "input", value?: ValueType): void;
}>();

const selectedItem = computed({
  get: () => props.value,
  set: (newValue) => {
    emit('input', newValue);
  }
})


</script>

<style lang="scss">
@import "../../assets/styles/main";

.text-field .v-input__append-inner {
  margin-top: unset !important;
  align-self: center;
}

.text-field {
  label {
    animation: none !important;
  }

  position: relative;

  .v-text-field__details {
    position: absolute;
    bottom: -28px;
    left: -12px;

    .v-messages__message {
      @include body-2;
    }
  }

  .v-input__prepend-inner {
    z-index: 1;
    margin-top: 6px !important;
    color: $elements  !important;
  }

  //default icon size and color
  .v-icon {
    .v-icon__component, .v-icon__svg {
      height: 24px;
      width: 24px;
      color: $secondaryMedium;
    }
  }

  //icon size and color for clear icon
  .v-input__icon--clear {
    .v-icon__component, .v-icon__svg {
      height: 24px;
      width: 24px;
      color: $secondaryMedium !important;
    }
  }

  &.error--text {
    fieldset {
      border: 1px solid $negative !important;
    }
  }

  .v-input__slot {
    min-height: 36px !important;
    margin-bottom: 0 !important;

    & fieldset {
      color: $elements  !important;
      border: 1px solid;
      background: $backgrounds;
    }

    input {
      @include body-2;
      font-size: 14px !important;

      /* Remove Arrows/Spinners */
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    & label {
      top: unset !important;
      color: $secondaryMedium;
      @include body-2;
    }

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
      border-radius: 4px;

      & fieldset {
        color: $elements  !important;
        border: 1px solid;
      }
    }

    &:focus-within {
      & fieldset {
        color: $accent  !important;
      }
    }

    z-index: 2;
  }
}

.text-field--disabled .v-input__slot {
  & fieldset {
    background: $elements;
  }
}

.eewc-text-field--error .v-input__slot {
  & fieldset {
    color: $negative;
  }
  .v-icon {
    .v-icon__component {
      color: $negative !important;
    }
  }
}
</style>
