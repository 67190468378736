<template>
  <v-expansion-panel
    class="expansion-panel"
    :disabled="isDisabled"
    @click="handleClick"
  >
    <v-expansion-panel-header class="expansion-panel-header">
      <template #actions>
        <v-icon
          color="primary"
          class="expansion-panel-header__chevron-icon"
          size="18"
        >
          $icon_arrow_down
        </v-icon>
      </template>
      <div class="expansion-panel-header__action">
        <slot name="action" />
      </div>
      <span class="expansion-panel-header__title">
        {{ title }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, defineEmits } from "vue";

/**
 * Types
 */

type Props = {
  title: string;
  isDisabled?: boolean;
};

/**
 * Component
 */

const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
});

const emit = defineEmits<{
  (e: "click", title: string): void;
  (e: "info-click"): void;
}>();

const handleClick = () => {
  emit("click", props.title);
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.expansion-panel {
  &-header {
    background: $elements;
    border-radius: 4px;
    height: 40px;
    min-height: 40px !important;
    padding: 10px 8px;
    display: flex;
    justify-content: flex-start;

    &__title {
      @include subtitle-2;
      color: $primary;
      order: 2;
      margin-left: 4px;
    }

    &__action {
      order: 3;
      flex-grow: 0;
      padding-right: 2px;
    }

    .v-expansion-panel-header__icon {
      justify-self: flex-start;
      order: 1;
    }
  }

  :deep(.v-expansion-panel-content__wrap) {
    padding: 0;
  }

  :deep(.v-expansion-panel-header) {
    .v-icon.expansion-panel-header__chevron-icon {
      rotate: (-90deg);
    }
  }

  &:not(:first-child)::after {
    border: none;
  }
}

.v-expansion-panel--active
  > .v-expansion-panel-header--active
  .v-expansion-panel-header__icon:not(
    .v-expansion-panel-header__icon--disable-rotate
  )
  .v-icon {
  rotate: (180deg) !important;
}
</style>
