<template>
  <div class="range-slider">
    <div
      class="range-slider__label subtitle-2 text-center"
      :class="[
        {
          'range-slider__label--disabled': disabled
        }
      ]"
    >
      {{ translations.name }}
    </div>
    <div class="range-slider__wrapper">
      <v-range-slider
        v-model="range"
        :disabled="disabled"
        :step="step"
        :min="min"
        :max="max"
        :thumb-label="thumbLabel"
        class="align-center"
      >
        <template
          v-if="!!thumbLabel"
          #thumb-label="props"
        >
          {{ props.value }}
        </template>
      </v-range-slider>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    value: number[],
    disabled?: boolean,
    step?: number,
    min?: number
    max?: number,
    thumbLabel?: boolean | string
    translations: {
      from : string,
      to: string,
      name: string
    }
  }>(),
  {
    disabled: false,
    step: 1,
    min: 0,
    max: 10,
  }
)

const range = computed({
  get: () => props.value,
  set: (newValue) => emit('input', newValue)
});

const emit = defineEmits<{
  (e: "input", value?: number[]): void;
}>();

</script>

<style lang="scss" scoped>
@import "../../../assets/styles/main";
.range-slider {
  &__wrapper{
    width: 100%;
  }
  &__label {
    color: $secondary;
    &--disabled {
      color: $secondaryMedium;
    }
  }
}

:deep {
  .v-slider {

    &:hover {
      cursor: pointer;
    }

    .v-slider__track-container {
      height: 8px;

      .v-slider__track-fill {
        background-color: $secondary!important;
      }

      .v-slider__track-background {
        border-radius: 20px;
        background-color: $elements!important;
      }
    }

    .v-slider__thumb-label {
      @include subtitle-1;
      background-color: transparent!important;
      color: $primaryLight;
      transform: translateX(-50%) translateY(40px)!important;

      > * {
        transform: none;
      }
    }

    .v-slider__thumb-container {
      transition: none!important;
      .v-slider__thumb {
        background: $primary!important;
        border: 4px solid $primaryWhite!important;
        box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.36);
        width: 20px;
        height: 20px;
        left: -11px;
        transition: box-shadow 0.2s;

        &:hover {
          cursor: pointer;
        }

        &:before,
        &:after {
          content: none;
        }
      }
      &:hover {
        .v-slider__thumb {
          background: $primaryLight!important;
        }
      }
      &.v-slider__thumb-container--active {
        .v-slider__thumb {
          box-shadow: none;
          background: $secondary!important;
        }

        .v-slider__thumb-label {
          color: $primary;
        }
      }
    }

    &.v-slider--disabled {
      .v-slider__thumb-container {
        .v-slider__thumb {
          background: $secondaryMedium!important;
        }
      }
    }
  }
}

</style>