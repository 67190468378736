<template>
  <div class="show-more">
    <div
      class="show-more__content-wrapper"
      :style="{ 
        maxHeight: isExpanded ? (contentHeight + 'px') : (maxHeight + 'px'), 
      }"
    >
      <div
        ref="contentRef"
      >
        <slot />
      </div>
    </div>
    <button-common
      v-if="showMoreButtonVisible"
      type="clear"
      append-icon="$icon_arrow_down"
      color="accent"
      class="show-more__button"
      :small-padding="true"
      :class="{'show-more__extended': isExpanded}"
      @click="isExpanded = !isExpanded"
    >
      {{ isExpanded ? showLessText : showMoreText }}
    </button-common>
  </div>
</template>
<script setup lang="ts">
import {ref, watch} from "vue";
import ButtonCommon from "../components/buttons/ButtonCommon.vue";

const props = defineProps<{
  maxHeight: number;
  showMoreText?: string;
  showLessText?: string;
}>();

const contentRef = ref();
const isExpanded = ref(false);
const showMoreButtonVisible = ref(false);
const contentHeight = ref(0);

const resizeObserver = new ResizeObserver(() => {
  contentHeight.value = contentRef.value?.offsetHeight;
  showMoreButtonVisible.value = contentHeight.value > props.maxHeight;
});

watch(contentRef, (newVal, oldVal) => {
  if (oldVal) {
    resizeObserver.unobserve(oldVal);
  }
  if (newVal) {
    resizeObserver.observe(newVal);
  }
});
</script>
<style lang="scss" scoped>
.show-more {
    &__content-wrapper {
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;
    }

    &__button {
        margin-left: -8px !important;
    }
}


::v-deep .show-more__extended .v-icon {
    transform: rotate(-180deg);
}


</style>
