<template>
  <v-app-bar
    app
    color="white"
    tile
    :height="height"
    class="eewc-top-bar"
    clipped-right
  >
    <div
      v-if="topBarBreadcrumbs !== undefined"
      class="d-flex align-center"
    >
      <tooltip :text="topBarBreadcrumbs.from">
        <template #content="{on, attrs}">
          <div
            v-bind="attrs"
            v-on="on"
          >
            <button-common
              append-icon="$icon_arrow_left"
              icon-color="primary"
              type="clear"
              icon
              @click="backClick"
            />
          </div>
        </template>
      </tooltip>
      <span>
        <tooltip
          :text="topBarBreadcrumbs.iconTooltip"
        >
          <template #content="{on, attrs}">
            <v-icon 
              size="24" 
              class="mr-1"
              v-bind="attrs"
              v-on="on"
            > 
              {{ topBarBreadcrumbs.icon }} 
            </v-icon>
          </template>
        </tooltip>
      </span>
      <span class="back__title">
        {{ topBarBreadcrumbs.title }}
      </span>
    </div>
    <template v-else>
      <slot name="searchBar" />
    </template>
    <v-spacer />
    <slot />
    <tooltip
      v-if="clockData"
      :text="clockData.timeZone"
    >
      <template #content="{on, attrs}">
        <div 
          v-bind="attrs"
          :class="{ 'subtitle-2': compressedFont, 'subtitle-1': !compressedFont }"
          data-testid="top-bar-clock-data"
          v-on="on"
        >
          {{ clockData.value }}
        </div>
      </template>
    </tooltip>
    <tooltip :text="fullscreenTooltip">
      <template #content="{on, attrs}">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <button-common
            data-testid="top-bar-btn-full-screen"
            append-icon="$icon_full_size"
            icon-color="primary"
            type="clear"
            icon
            class="mx-5"
            @click="$emit('emit-fullscreen')"
          />
        </div>
      </template>
    </tooltip>
  </v-app-bar>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Tooltip from '../tooltip/Tooltip.vue';
import vuetify from '../../service/useVuetify';
import ButtonCommon from '../buttons/ButtonCommon.vue';

type ClockInformation = {
  timeZone: string
  value: string
};

const props = withDefaults(
  defineProps<{
    headerTitle?: string,
    fullscreenTooltip?: string,
    clockData?: ClockInformation,
    returnArrow?: boolean,
    newNotification?: boolean,
    topBarBreadcrumbs?: {
      backEvent: (event: MouseEvent) => void,
      from: string,
      title: string,
      icon?: string,
      iconTooltip?: string,
    },
  }>(),
  {
    fullscreenTooltip: '',
    clockValue: '',
  }
)

const height = computed(() => {
  switch (vuetify.value.breakpoint.name) {
    case "sm":
      return 44;
    case "md":
    case "lg":
      return 56;
    case "xl":
      return 64;
    default:
      return 64;
  }
})

const compressedFont = computed(() => {
  return vuetify.value.breakpoint.name === "sm" || vuetify.value.breakpoint.name === "md";
})

function backClick(event: MouseEvent) {
  if(props.topBarBreadcrumbs) {
    props.topBarBreadcrumbs.backEvent(event);
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/main";

.eewc-top-bar {
  box-shadow: 0px 2px 12px rgba(42, 52, 64, 0.08) !important;
  color: $primary !important;
  .back {
    &__from, &__title {
      @include headline-6;
      margin-left: 4px;
    }
  }
  &.v-app-bar.v-app-bar--fixed.v-sheet{
    z-index: 9;
  }
}
</style>