<template>
  <div>
    <v-autocomplete
      v-model="selectedItem"
      :label="labelText"
      :items="items"
      :append-icon="appendIcon"
      filled
      dense
      :search-input.sync="paginationRef.searchText"
      height="36px"
      :no-filter="paginated"
      single-line
      outlined
      class="dropdown__simple"
      :class="{ 'dropdown__simple--disabled' : disabled,
                'dropdown__simple--no-translate ': stopIconTransform }"
      :disabled="disabled"
      return-object
      item-color="white"
      :loading="loading"
      :attach="isAttached"
      :no-data-text="!paginated ? '$vuetify.noDataText' : ''"
    >
      <template
        v-if="paginated"
        #append-item
      >
        <div v-intersect="handleIntersection" />
        <loading-spinner
          v-if="!paginationRef.loaded && !paginationRef.completed"
          is-loading
          :absolute="false"
          :class="['infinite-loader', { 'infinite-loader--margin': !items.length }]"	
        />
      </template>
      <template 
        v-if="paginated"
        #no-data
      >
        <div
          v-if="paginationRef.completed"
        >
          {{ noDataText }}
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
  export type AutoCompleteValue = { text: string, value: string | number } | string;
</script>


<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import LoadingSpinner from "../../components/loaders/LoadingSpinner.vue";
import { IntersectionPaginationOption, IntersectionStateChanger } from '@/utils/types';

const props = withDefaults(defineProps<{
  disabled?: boolean,
  value: AutoCompleteValue,
  items: Array<AutoCompleteValue>,
  labelText?: string,
  loading?: boolean,
  appendIcon?: string,
  stopIconTransform?: boolean,
  isAttached?: boolean,
  paginated?: boolean,
  noDataText?: string
  }>(),
  {
    appendIcon: "$icon_arrow_down",
    stopIconTransform: false,
    disabled: false,
    labelText: '',
    isAttached: false,
    paginated: false,
    noDataText: ''
  });

const emit = defineEmits<{
  (e: "input", value?: AutoCompleteValue): void;
  (e: "search", value: { searchText: string | undefined | null, state: IntersectionStateChanger }): void;
  (e: "infinite", value: IntersectionStateChanger): void;
}>();

const selectedItem = computed({
  get: () => props.value,
  set: (newValue) => {
    emit('input', newValue);
  }
})

function handleIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver, isIntersecting: boolean) {
  if(isIntersecting) {
    if(paginationRef.value.completed) return;
    paginationRef.value.loaded = false;
    emit('infinite', state);
  }
}

const paginationRef = ref<IntersectionPaginationOption>({
  loaded: false,
  completed: false,
  searchText: undefined, 
  bounceTimer: undefined
})

watch(
  () => paginationRef.value.searchText,
  (newValue, oldValue) => {
    if(!props.paginated) return;
    if(newValue === oldValue) return;
    state.reset();
    
    if(paginationRef.value.searchText?.length && paginationRef.value.searchText === props.value.text) { // watcher for searchText will be called whenever an option is selected from dropdown, to avoid search in the case the condition is added
      return;
    }
    // debounce for the search
    clearTimeout(paginationRef.value.bounceTimer);
    paginationRef.value.bounceTimer = setTimeout(() => {
      emit('search', {searchText: paginationRef.value.searchText, state});
    }, 500);
  }
)

const state: IntersectionStateChanger = {
  loaded: () => {
    paginationRef.value.loaded = true;
  },
  completed: () => {
    paginationRef.value.completed = true;
  },
  reset: () => {
    paginationRef.value.loaded = false;
    paginationRef.value.completed = false;
  }
};
</script>


<style lang="scss">

@import "../../assets/styles/main";
.dropdown__simple .v-input__append-inner{
  margin: 6px 0 !important;
  align-self: center;
}

.dropdown__simple .v-label {
  position: unset !important;
}

.dropdown__simple {
  position: relative;
  .v-text-field__details {
    position: absolute;
    bottom: -24px;
    left: -12px;
    .v-messages__message {
      @include body-2;
    }
  }
  input {
    font-size: 14px;
  }

  &.error--text {
    fieldset {
      border: 1px solid $negative !important;
    }
  }
  .v-input__slot {
    min-height: 36px !important;
    padding-right: 8px !important;
    & fieldset {
      background: $backgrounds;
      color: $elements !important;
      border: 1px solid;
    }
    input {
      font-size: 14px;
      color: $primary;
    }

    & label {
      @include body-2;
      color: $secondaryMedium;
    }

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    }

    &:focus-within{
      & fieldset {
        border: 1px solid $accent;
      }
    }
    z-index: 2;
    .v-list-item{
      min-height: 28px !important;
    }

    .v-list-item--active{
      background: $accent !important;
      border-radius: 4px;
    }
  }
}

.infinite-loader {
  text-align: center;

  &--margin {
    margin-top: -30px;
  }
}

.v-autocomplete__content.v-menu__content {
  box-shadow: 2px 4px 12px 0px #2A344014, 0 0 0 1px $elements;
  border-radius: 4px;
  margin-top: 4px;
  max-height: 235px !important;
  padding: {
    right: 4px;
    left: 4px;
  }

  .v-list--dense .v-list-item {
    padding: 0 12px;

    &__title {
      @include body-2;
    }

    &__mask {
      color: $primaryMedium !important;
      background: transparent !important;
      font-weight: 700 !important;
    }
  }
}

.dropdown__simple {
  &--disabled .v-input__slot {
    & fieldset {
      background: $elements;
    }
  }

  &--no-translate {
    .notranslate {
      transform: none!important;
    }
  }

  & .v-icon {
    color: $secondaryMedium !important;
    
    & .primary--text{
      color: $secondaryMedium !important;
    }
  }
}
</style>
