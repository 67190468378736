<template>
  <div>
    <v-chip
      v-for="(chip, i) in items"
      :id="'chip' + i"
      :key="chip"
      class="chips__choice"
      :class="chip == value ? 'chips__choice--checked' : null"
      :disabled="!!disabled"
      label
      :ripple="false"
      @click="updateValue(chip)"
    >
      {{ chip }}
    </v-chip>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "input", value: string): void;
}>();

defineProps<{
  value: string;
  items: string[];
  disabled?: boolean;
}>();

function updateValue(value: string) {
  emit("input", value);
}
</script>

<style lang="scss">
@import "../../assets/styles/main";
$vuetify-hover: #f6f6f6; // to make an invisible border, to maintain the width of the chip on :hover
.chips__choice {
  color: $secondary !important;
  border-radius: 40px !important;
  &:not(:last-child) {
    margin: unset;
    margin-right: 4px;
  }
  @include body-2;
  height: 24px !important;
  background: white !important;
  border: solid 1px $elements !important;

  &:hover {
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32) !important;
    border: solid 1px $vuetify-hover !important;
  }

  &--checked {
    background: $primary !important;
    color: white !important;
    border: none !important;
  }

  &:active {
    background-color: $elements !important;
    border: none !important;
  }

  &:focus-visible {
    border: solid 1px $primary !important;
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
  }
}

.chips__choice--checked {
  border: solid 1px $primary !important;

  &:hover {
    background: $primary !important;
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32) !important;
    border: solid 1px $primary !important;
  }
}
</style>
