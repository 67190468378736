<template>
  <v-skeleton-loader
    class="skeleton-loader"
    :class="skeletonClasses"
    type="image"
    :style="{ maxWidth, height }"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
const props = withDefaults(
  defineProps<{
    type?: string;
    maxWidth?: string,
    fullHeight?: boolean,
    height?: string;
  }>(),
  {
    type: "text",
  }
);

const skeletonClasses = computed(() => {
  return {
    'skeleton-loader--full-height': props.fullHeight,
    [`${props.type}`]: true
  }
})
</script>

<style lang="scss">
  @import "../../assets/styles/main";

  .skeleton-loader {
    &.text{
      height: 16px;
    }

    &.tooltiped-text {
      height: 22px;
    }

    &.title {
      height: 24px;
      max-width: 220px;

      @media (min-width: $MD) {
        max-width: 466px;
      }
    }
    &.select {
      height: 36px;
    }

    &.image, .v-skeleton-loader__image {
      height: 222px;
    }

    &--full-height{
      &.image, .v-skeleton-loader__image {
        height: 100%;
      }
      
      &.text{
        height: 100%;
      }
    }

    &.big-icon{
      height: 24px;
      width: 24px;
    }

    &.icon{
      height: 16px;
      width: 16px;
    }

    &.input {
      height: 36px;
    }
    &.action-card {
      height: 100px;
    }
  }
</style>
