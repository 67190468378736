<template>
  <v-navigation-drawer
    :class="`menu-bar ${isMini ? 'menu-bar--mini' : ''}`"
    :mini-variant="isMini"
    floating
    app
    permanent
    disable-route-watcher
    :width="(isMini && vuetify.breakpoint.mdAndUp) ? 48 : navigationWidth"
  >
    <div class="menu-bar__extended-area" />
    <logo
      :mini="isMini"
      :loading="isLogoLoading"
      :logo-margin-s-m="logoMarginSM"
      :logo-margin-m-d="logoMarginMD"
      :logo-margin-l-g="logoMarginLG"
      :logo-margin-x-l="logoMarginXL"
      :logo-small-width="logoSmallWidth"
      :logo-small-height="logoSmallHeight"
      :logo-small="logoSmall"
      :logo-big-width="logoBigWidth"
      :logo-big-height="logoBigHeight"
      :logo-big="logoBig"
      :alt-logo-small="altLogoSmall"
      :alt-logo-big="altLogoBig"
      @click.native.exact="emit('on-click-logo', $event)"
      @dblclick.shift.native="emit('on-shift-dblclick-logo')"
    />
    <v-divider class="sidebar__divider--up" />

    <nav-items
      :items="items"
      :mini="isMini"
      :expand-tooltip="tooltips.expandTooltip"
      :collapse-tooltip="tooltips.collapseTooltip"
      @update:mini="updateMini"
      @subItemClicked="subItemClicked"
      @infiniteHandler="infiniteHandler"
    />

    <support-contact
      v-if="supportEmail && supportPhone"
      :mini="isMini"
      :email="supportEmail"
      :phone="supportPhone"
      :email-tooltip="tooltips.emailTooltip"
      :phone-tooltip="tooltips.phoneTooltip"
    />

    <nav-footer
      :mini="isMini"
      :help-items="helpItems"
      :user-items="userItems"
      :user-name="userName"
      :email="email"
      :profile-tooltip="tooltips.profileTooltip"
      @logout="emit('logout')"
      @onusershiftdblclick="emit('onusershiftdblclick')"
    />
  </v-navigation-drawer>
</template>

<script setup lang="ts">
export type HelpItems = Array<{ click: () => void; icon: string; name: string; }>
export type UserItems = Array<{ click: () => void; icon: string; name: string; active?: boolean; isLoading?: boolean; }>
import Logo from './Logo.vue';
import NavFooter from './NavFooter.vue';
import SupportContact from './SupportContact.vue';
import { computed } from 'vue';
import vuetify from '../../../service/useVuetify';
import NavItems from './NavItems.vue';
import { IntersectionStateChanger } from '@/utils/types';

const props = withDefaults(
  defineProps<{
    items: Array<any>,
    helpCenter: string,
    helpItems: HelpItems,
    userItems: UserItems,
    email: string,
    phone: string,
    supportEmail: string,
    supportPhone: string,
    tooltips: {
      expandTooltip: string,
      collapseTooltip: string,
      emailTooltip: string,
      phoneTooltip: string,
      profileTooltip: string,
    },
    userName: string,
    logoSmall: string,
    logoSmallWidth?: string,
    logoSmallHeight?: string,
    logoBig: string,
    logoBigWidth?: string,
    logoBigHeight?: string,
    logoMarginSM?: string,
    logoMarginMD?: string,
    logoMarginLG?: string,
    logoMarginXL?: string,
    isMini?: boolean,
    isLogoLoading?:boolean,
    altLogoSmall?: string,
    altLogoBig?: string
  }>(),
  {
    logoSmallWidth: "40px",
    logoSmallHeight: "40px",
    logoBigWidth: "120px",
    logoBigHeight: "44px",
    logoMarginSM: 'margin: 12px 4px' ,
    logoMarginMD: 'margin: 12px 8px',
    logoMarginLG: 'margin: 10px 56px 21px 38px',
    logoMarginXL: 'margin: 10px 76px 21px 59px',
    isMini: false,
    isLogoLoading:false
  }
);

const emit = defineEmits<{
  (e: "on-click-logo", event: Event): void;
  (e: "on-shift-dblclick-logo"): void;
  (e: "get-subItemId-clicked", index: number, subItemIndex?: number): void;
  (e: "updateMini", value: boolean): void;
  (e: "infiniteHandler", parentIndex: number, state: IntersectionStateChanger): void;
  (e: "logout"): void;
  (e: "onusershiftdblclick"): void;
}>();

function infiniteHandler (parentIndex: number, state: IntersectionStateChanger) {
  emit('infiniteHandler', parentIndex, state);
}

function updateMini(value: boolean){
  emit('updateMini', value);
}

function subItemClicked(index: number, subItemIndex?: number) {
  emit('get-subItemId-clicked', index, subItemIndex);
}

const navigationWidth = computed(() => {
    switch (vuetify.value && vuetify.value.breakpoint.name) {
      case 'sm': return 48
      case 'md': return 56
      case 'lg': return 212
      case 'xl': return 252
      default: return 48
    }
})
</script>

<style lang="scss">
@import "../../../assets/styles/main";

.menu-bar {
  display: flex;
  flex-direction: column;
  background-color: $primary !important;
  overflow: unset !important;
  padding-right: 4px;

  .v-navigation-drawer__content {
    overflow-y: unset !important;
    display: flex;
    flex-direction: column;
  }

  .menu-bar__extended-area {
    position: absolute;
    width: 16px;
    height: 100vh;
    right: -16px;
  }

  &:hover {
    .expand-buttton {
      opacity: 1;
      transition-delay: .2s;
      pointer-events: unset;
    }
  }

  .v-divider {
    border-color: $primaryLight !important;
    height: 1px;
    border-radius: 0px;
    display: block;

    @media (max-width: $MD) {
      margin: 0px 9px 32px;
    }
    @media (min-width: $MD) {
      margin: 0px 10.5px 32px;
    }
    @media (min-width: $LG) {
      margin: 15px 12px 24px;
    }
  }

  .nav-icon {
    @include width-height (20px, 20px);
    color: white !important;

    @media (max-width: $LG) {
      margin-right: 0;
      @include width-height (24px, 24px);
    }

    @media (max-width: $LG) {
      margin-right: 0 !important;
    }
  }
}

</style>