<template>
  <v-menu
    :id="attachId"
    v-model="isOpen"
    :close-on-content-click="false"
    :max-height="menuMaxHeight"
    :max-width="menuMaxWidth"
    :min-width="menuMinWidth"
    nudge-bottom="6"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-chip
        :id="attachId"
        v-bind="attrs"
        :class="['chips-filter', { 'chips-filter--open': isOpen }]"
        label
        v-on="on"
        @click="handleChipClick"
      >
        <v-icon
          left
          :color="iconColor"
          @click="handleRemove"
        >
          $icon_close
        </v-icon>
        <span>{{ text }}</span>
        <v-icon
          right
          :color="iconColor"
          :class="{ 'icon--inverted': isOpen }"
        >
          $icon_arrow_down
        </v-icon>
      </v-chip>
    </template>
    <v-container
      v-if="isOpen"
      fluid
      class="popup-menu"
    >
      <slot />
    </v-container>
  </v-menu>
</template>

<script setup lang="ts">
import { computed } from "vue";

/**
 * Types
 */

type Props = {
  attachId?: string;
  menuMaxHeight?: string;
  menuMaxWidth?: string;
  menuMinWidth?: string;
  text: string;
  value: boolean;
};

/**
 * Component
 */

const props = withDefaults(defineProps<Props>(), {
  attachId: undefined,
  menuMaxHeight: "456",
  menuMaxWidth: "476",
  menuMinWidth: "476",
});

const emit = defineEmits<{
  (e: "input", value: boolean): void;
  (e: "remove"): void;
}>();

const isOpen = computed({
  get: () => props.value,
  set: (newValue) => {
    emit("input", newValue);
  },
});

const iconColor = computed(() => (isOpen.value ? "primaryWhite" : undefined));

const handleChipClick = () => {
  isOpen.value = !isOpen.value;
};

const handleRemove = () => {
  emit("remove");
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/main.scss";

.chips-filter {
  @include body-2;
  border-radius: 40px !important;
  height: 24px !important;
  border: 1px solid $elements !important;
  background: $backgrounds !important;

  &:hover {
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    background: $backgrounds !important;
  }

  &:active {
    background: $primary !important;
    color: $primaryWhite !important;
  }

  &:focus-visible {
    border: solid 1px $primary !important;
    box-shadow: inset 0 0 0 1px #eee;
  }

  &--open {
    background: $primary !important;
    color: $primaryWhite !important;
    border-color: transparent !important;

    &:hover {
      background: $primary !important;
      border-color: transparent !important;
    }
  }
}

.icon--inverted {
  transform: rotate(180deg);
}

.popup-menu {
  padding: 24px 20px 24px 24px;

  &__content {
    overflow: auto;
  }
}

.v-menu__content.menuable__content__active {
  padding: 0;
}
</style>
