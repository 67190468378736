<template>
  <v-card-text
    v-if="tabNames"
    class="tabs"
  >
    <v-tabs
      v-model="selectedTab"
      hide-slider
    >
      <v-tab
        v-for="(tab, index) in tabNames"
        :key="index"
        class="tabs__tab"
        :data-testid="`tabs__tab-${index}-${tab}`"
        :ripple="false"
        active-class="tabs__tab--active"
      >
        <span class="subtitle-2"> {{ tab }}</span>
      </v-tab>
    </v-tabs>
    <v-divider />
    <v-tabs-items v-model="selectedTab">
      <slot name="tabContent" />
    </v-tabs-items>
  </v-card-text>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, computed, defineEmits, watch } from 'vue';

const emit = defineEmits<{
  (e: 'changeActiveTab', value: number): void;
}>();

const props = withDefaults(
  defineProps<{
    tabNames: string[];
    activeTab: number;
  }>(),
  {
    activeTab: 0,
  }
);

watch(
  () => props.activeTab,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      selectedTab.value = newValue;
    }
  }
);

const selectedTab = computed({
  get: () => props.activeTab,
  set: (newValue) => {
    if (newValue !== props.activeTab) {
      emit('changeActiveTab', newValue);
    }
  },
});
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main.scss';
.tabs {
  .v-tab {
    text-transform: unset;
    color: $accent;
    letter-spacing: 0;
    padding: 0;
  }
}

.tabs__tab {
  min-width: unset;

  &:hover::before {
    opacity: 0 !important;
  }

  span {
    padding: 4px 16px !important;

    &:hover {
      background: $accentClear;
      border-radius: 4px;
    }
  }

  &.tabs__tab--active {
    & span {
      background: $accentClear !important;
      border-radius: 4px;
    }

    &:focus::before {
      opacity: 0;
    }
  }
}
</style>
