<template>
  <div class="upload-file__wrapper">
    <div
      class="drop-area"
      :class="{
        'drop-area--border-dashed': !file,
        'wrong-file-error': wrongTypeError,
        'upload-file-error': uploadFileError
      }"
      @drop.prevent="onDrop"
    >
      <template v-if="!file">
        <label
          for="file-input"
          class="upload-file__label"
        >
          <span class="d-block mb-3">{{ uploadText }}</span>
          <span class="d-block mb-3">{{ uploadTextLine2 }}</span>
          <input
            id="file-input"
            ref="inputFile"
            class="upload-file__input"
            type="file"
            :accept="acceptType"
            @change="onInputChange"
          >
          <button-common
            color="primary"
            @click="$refs.inputFile.click()"
          >
            {{ uploadButtonLabel }}
          </button-common>
        </label>
      </template>

      <template v-else>
        <div class="text-left">
          <template v-if="fileUrl">
            <v-icon
              color="secondary"
              size="24"
              @click="clearFileInfo()"
            >
              $icon_close
            </v-icon>
            <div class="upload-file__image-wrapper">
              <img
                :src="fileUrl"
                :alt="file.name"
                :title="file.name"
              >
            </div>
          </template>

          <div class="upload-file__info d-flex align-center">
            <v-icon
              color="secondary"
              size="24"
              @click="clearFileInfo()"
            >
              $icon_close
            </v-icon>
            <span class="upload-file__name">
              {{ file.name }}
            </span>
            <span class="upload-file__size ml-auto">
              {{ fileSize }}
            </span>
          </div>
          <progress-bar
            class="my-5"
            :progress="uploadProgress"
          />
        </div>
      </template>
    </div>
    <div
      v-if="errorMessage"
      class="upload-file__error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, onMounted, onUnmounted} from 'vue'
import ButtonCommon from './buttons/ButtonCommon.vue'
import ProgressBar from './ProgressBar.vue'

const props = defineProps<{
  uploadText: string;
  uploadTextLine2: string;
  uploadButtonLabel: string;
  acceptType: string;
  fileSizeUnits: string[];
  wrongTypeErrorMessage: string;
  uploadFileErrorMessage: string;
  uploadedFileUrl?: string;
  uploadedFile?: File;
}>();
const emit = defineEmits<{
  (e: "upload-done", object?: {
    formData: FormData,
    fileUrl: string,
    addedFile: File
  }): void;
  (e: "removed-file"): void;
  (e: "upload-init"): void;
}>();

const file = ref<File | null>(null)
const fileSize = ref('')
const fileUrl = ref('')
const wrongTypeError = ref(false)
const uploadFileError = ref(false)
const events = ['dragenter', 'dragover', 'dragleave', 'drop']
const errorMessage = ref('')
const uploadProgress = ref(0)

function onInputChange(e: Event) {
  const target = e && e.target as HTMLInputElement
  const file = target && target.files && target.files.length ? target.files[0] : undefined
  if (file) {
    getFileInfo(file)
  }
}

function onDrop(e: DragEvent) {
  const file = e && e.dataTransfer && e.dataTransfer.files ? e.dataTransfer.files[0] : undefined

  if (file) {
    getFileInfo(file)
  }
}

function preventDefaults(e: { preventDefault: () => void; }) {
  e.preventDefault()
}

function clearFileInfo(errorMessageStr?: string) {
  file.value = null;
  uploadFileError.value = false;
  wrongTypeError.value = false
  errorMessage.value = errorMessageStr ? errorMessageStr : ''
  uploadProgress.value = 0
  fileSize.value = ''
  fileUrl.value = ''
  emit('removed-file')
}

function getFileSize(size: number){
  const fileSizeUnits = props.fileSizeUnits;
  const k = 1000;

  if (size == 0) {
    return `0 ${fileSizeUnits}`;
  }

  let i = Math.floor(Math.log(size) / Math.log(k));

  i = i >= fileSizeUnits.length ? fileSizeUnits.length - 1 : i;

  return parseFloat((size / Math.pow(k, i)).toFixed(1)) + ' ' + fileSizeUnits[i];
}

function getFileInfo(addedFile: File) {
  emit('upload-init')
  clearFileInfo();

  if (!addedFile) {
    return
  }

  if (!addedFile.type || !props.acceptType.includes(addedFile.type)) {
    wrongTypeError.value = true
    errorMessage.value = props.wrongTypeErrorMessage
    return
  }

  file.value = addedFile
  fileSize.value = getFileSize(addedFile.size)

  const reader = new FileReader();

  reader.onload = async () => {
    const data = reader.result;

    uploadProgress.value = 60

    if (typeof data === 'string') {
      const formData = new FormData();
      formData.append('data', addedFile)

      uploadProgress.value = 100
      fileUrl.value = URL.createObjectURL(addedFile)
      emit('upload-done', {
        formData,
        fileUrl: fileUrl.value,
        addedFile
      })
    }
  };

  reader.onerror = () => {
    clearFileInfo();
    errorMessage.value = props.uploadFileErrorMessage
    uploadFileError.value = true;
  };

  reader.readAsBinaryString(addedFile);
}

onMounted(() => {
  events.forEach((eventName) => {
    document.body.addEventListener(eventName, preventDefaults)
  })
  if (props.uploadedFileUrl && props.uploadedFile) {
    fileUrl.value = props.uploadedFileUrl;
    file.value = props.uploadedFile;
    uploadProgress.value = 100
  }
})

onUnmounted(() => {
  events.forEach((eventName) => {
    document.body.removeEventListener(eventName, preventDefaults)
  })
})
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.drop-area {
  border: 1px solid $secondaryLight;
  border-radius: 4px;
  padding: 16px;

  &--border-dashed {
    border: 1px dashed $secondaryMedium;
    padding: 0;
  }
  &.wrong-file-error {
    border: 1px dashed $negativeDark;
  }
  &.upload-file-error {
    border: 1px solid $negativeDark;
  }
}
.upload-file {
  &__wrapper {
    display: block;
    text-align: center;
    margin: auto;
  }

  &__label {
    display: block;
    padding: 24px;
  }

  &__input {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
  }

  &__info {
    padding: 12px 0;
  }

  &__name {
    color: $secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 10px;
  }

  &__size {
    color: $secondaryMedium;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    white-space: nowrap;
  }

  &__error-message {
    color: $negative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    margin-top: 12px;
  }

  &__image-wrapper {
    position: relative;
    padding: 16px 30px;
    border-bottom: 2px solid $elements;
    margin-bottom: 30px;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
}
</style>
