<template>
  <div
    :class="[
      'drag-n-drop',
      {
        'drag-n-drop--full-width': fullWidth
      }
    ]"
  >
    <div class="drag-n-drop__part--left">
      <div class="part__title">
        {{ leftTitle }}
      </div>
      <SearchBar
        v-if="searchable"
        v-model="currentSearchLeft"
        icon="$icon_funnel"
        class="drag-n-drop__search"
        :collections="[]"
        :placeholder="searchPlaceholders?.left ?? ''"
        :show-collection="false"
        @input="() => getVisibilityMap(currentSearchLeft, 'left')"
      />
      <div class="part__area">
        <draggable
          class="area__list"
          :list="listValue"
          group="items"
          :disabled="disableDrag"
          @change="elementAddedRemoved"
          @start="handleDragStart"
          @end="handleDragEnd"
        >
          <div
            v-for="element in listValueSearch"
            :key="element.value"
            :class="[
              'area__item' ,
              isHovered && 'area__item--hovered',
              element.isHidden && 'd-none'
            ] "
            @click="elementClicked(element, 'left')"
          >
            {{ element.text }}
          </div>

          <div
            v-if="isListValueEmpty"
            class="area__placeholder"
          >
            {{ placeholderLeft }}
          </div>
          <div 
            v-if="(!paginated && searchable && !listValueSearch.length) || (paginated && searchable && paginationLeftRef.loaded && !listValueSearch.length )"
            class="subtitle-2 pl-3"
          >
            {{ noSearchResultText }}
          </div>
          <div
            v-if="paginated"
            v-intersect="leftSideHandleIntersection"
          />
          <loading-spinner
            v-if="paginated && !paginationLeftRef.loaded && !paginationLeftRef.completed"
            is-loading
            :size="20"
            color="primary"
            :absolute="false"
            :class="['infinite-loader', { 'infinite-loader--margin': !listValueSearch.length }]"	
          />
        </draggable>
      </div>
      <button-common
        v-if="bulkAction"
        type="clear"
        color="primary"
        class="area__move-button--left"
        @click="moveRight()"
      >
        <div>
          {{ addAllText }}
          <v-icon
            size="24"
            color="primary"
          >
            $icon_arrow_right
          </v-icon>
        </div>
      </button-common>
    </div>
    <div class="drag-n-drop__part--right">
      <div class="part__title">
        {{ rightTitle }}
      </div>
      <SearchBar
        v-if="searchable"
        v-model="currentSearchRight"
        icon="$icon_funnel"
        class="drag-n-drop__search"
        :collections="[]"
        :placeholder="searchPlaceholders?.right ?? ''"
        :show-collection="false"
        @input="visibilityPlanRight = getVisibilityMap(currentSearchRight, 'right')"
      />
      <div class="part__area">
        <draggable
          class="area__list"
          :list="valueList"
          group="items"
          :disabled="disableDrag"
          @start="handleDragStart"
          @end="handleDragEnd"
        >
          <div
            v-for="element in valueListSearch"
            :key="element.value"
            :class="[
              'area__item' ,
              isHovered && 'area__item--hovered',
              element.isHidden && 'd-none'
            ] "
            @click="elementClicked(element, 'right')"
          >
            {{ element.text }}
          </div>
          <div
            v-if="(!paginated && isValueListEmpty ) || (paginated && isValueListEmpty && !currentSearchRight.length && paginationRightRef.loaded && paginationRightRef.completed)"
            class="area__placeholder"
          >
            {{ placeholderRight }}
          </div>
          <div 
            v-if="(!paginated && searchable && !valueListSearch.length) || (paginated && searchable && paginationRightRef.loaded && !valueListSearch.length )"
            class="subtitle-2  pl-3"
          >
            {{ noSearchResultText }}
          </div>
          <div
            v-if="paginated"
            v-intersect="rightSideHandleIntersection"
          />
          <loading-spinner
            v-if="paginated && !paginationRightRef.loaded && !paginationRightRef.completed"
            is-loading
            :absolute="false"
            :size="20"
            color="primary"
            :class="['infinite-loader', { 'infinite-loader--margin': !valueListSearch.length }]"	
          />
        </draggable>
      </div>
      <button-common
        v-if="bulkAction" 
        type="clear"
        color="primary"
        class="area__move-button--right"
        @click="moveLeft()"
      >
        <div>
          <v-icon
            size="24"
            color="primary"
          >
            $icon_arrow_left
          </v-icon>
          {{ removeAllText }}
        </div>
      </button-common>
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonCommon from '../buttons/ButtonCommon.vue';
import { computed, ref } from 'vue';
import draggable from "vuedraggable";
import SearchBar from "../text-inputs/searchBar/SearchBar.vue";
import * as types from "./types";
import LoadingSpinner from "../../components/loaders/LoadingSpinner.vue";
import { IntersectionPaginationOption, IntersectionStateChanger } from '@/utils/types';

type OperationJSON = {
  [key in "added" | "removed" | "moved"]: {element: types.ArrayValue, oldIndex: number};
};

const props = withDefaults(defineProps<{
  list: types.ArrayValue[],
  value: types.ArrayValue[],
  rightTitle: string;
  leftTitle: string;
  addAllText?: string;
  removeAllText?: string;
  placeholderLeft?: string;
  placeholderRight?: string;
  fullWidth?: boolean;
  searchable?: boolean;
  searchPlaceholders?: types.SearchPlaceholders;
  bulkAction?: boolean;
  paginated?: boolean;
  disableDrag?: boolean;
  noSearchResultText?: string;
}>(), {
    placeholderLeft: '',
    placeholderRight: '',
    fullWidth: false,
    searchable: false,
    bulkAction: true,
    paginated: false,
    searchPlaceholders: () => ({
      right: '',
      left: '',
    }),
    disableDrag: false,
    addAllText: '',
    removeAllText: '',
});

const emit = defineEmits<{
  (e: "input", value: types.ArrayValue[]): void;
  (e: "infinite-left", value: {search: string, state: IntersectionStateChanger}): void;
  (e: "infinite-right", value: {search: string, state: IntersectionStateChanger}): void;
  (e: "search-left", value: {search: string, state: IntersectionStateChanger}): void;
  (e: "search-right", value: {search: string, state: IntersectionStateChanger}): void;
  (e: "data-changed", value: {added: types.ArrayValue[], removed: types.ArrayValue[]}): void;
}>();

const isHovered = ref<boolean>(true)
const currentSearchLeft = ref<string>('');
const currentSearchRight = ref<string>('');
const visibilityPlanLeft = ref<Set<types.ArrayValue>>(new Set([]));
const visibilityPlanRight = ref<Set<types.ArrayValue>>(new Set([]));

const listValueRef = ref<types.ArrayValue[]>(props.list);
const valueListRef = ref<types.ArrayValue[]>(props.value);

const listValue = computed({
  get: () => {
    if(!props.paginated) {
      return listValueRef.value;
    }
    const combinedArr = [...addedItems.value.filter((item) => item.text.toLowerCase().indexOf(currentSearchLeft.value.toLowerCase()) !== -1), ...props.list ];
    return combinedArr.filter((item) => !removedItems.value.some((removeItem) => removeItem.value === item.value));
  },
  set:(val) => {
    if(!props.paginated) {
      listValueRef.value = val;
    }
  }
});	
const valueList = computed({
  get: () => {
    if(!props.paginated) {
      return valueListRef.value;
    }
  const combinedArr = [...removedItems.value.filter((item) => item.text.toLowerCase().indexOf(currentSearchRight.value.toLowerCase()) !== -1), ...props.value ];
  return combinedArr.filter((item) => !addedItems.value.some((addedItem) => addedItem.value === item.value));
  },
  set: (val) => {
    if(!props.paginated) {
      valueListRef.value = val;
    }
  }
});
const allItems = computed(()=> [ ...valueList.value, ...listValue.value ])
const listValueSearch = computed(() => {
  return props.paginated ? listValue.value 
  : listValue.value.map((item) => ({
    ...item,
    isHidden: !visibilityPlanLeft.value.has(item) && !!currentSearchLeft.value
  }))
});
const valueListSearch = computed(() => {
  return props.paginated ? valueList.value 
  : valueList.value.map((item) => ({
    ...item,
    isHidden: !visibilityPlanRight.value.has(item) && !!currentSearchRight.value
  }))
});

const isListValueEmpty = computed(() => (listValue.value.length === 0));
const isValueListEmpty = computed(() => (valueList.value.length === 0));

const paginationLeftRef = ref<IntersectionPaginationOption>({
  loaded: false,
  completed: false,
})

const paginationRightRef = ref<IntersectionPaginationOption>({
  loaded: false,
  completed: false,
})

const stateLeft: IntersectionStateChanger = {
  loaded: () => {
    paginationLeftRef.value.loaded = true;
  },
  completed: () => {
    paginationLeftRef.value.completed = true;
  },
  reset: () => {
    paginationLeftRef.value.loaded = false;
    paginationLeftRef.value.completed = false;
  }
};

const stateRight: IntersectionStateChanger = {
  loaded: () => {
    paginationRightRef.value.loaded = true;
  },
  completed: () => {
    paginationRightRef.value.completed = true;
  },
  reset: () => {
    paginationRightRef.value.loaded = false;
    paginationRightRef.value.completed = false;
  }
};
let bounceTimer: ReturnType<typeof setTimeout> | undefined
const addedItems = ref<types.ArrayValue[]>([]);
const removedItems = ref<types.ArrayValue[]>([]);

function leftSideHandleIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver, isIntersecting: boolean) {
  if(isIntersecting) {
    if(paginationLeftRef.value.completed) return;
    paginationLeftRef.value.loaded = false;
    emit('infinite-left', {search: currentSearchLeft.value, state: stateLeft});
  }
}

function rightSideHandleIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver, isIntersecting: boolean) {
  if(isIntersecting) {
    if(paginationRightRef.value.completed) return;
    paginationRightRef.value.loaded = false;
    emit('infinite-right', {search: currentSearchRight.value, state: stateRight});
  }
}

function elementAddedRemoved(action: OperationJSON) {
  if(!props.paginated) {
    change();
    return;
  }
  const eventType = Object.keys(action)[0];

  if(eventType === "added") {
    const index = removedItems.value.findIndex(element => element.value === action.added.element.value);
    if (index !== -1) {
      removedItems.value.splice(index, 1);
    } else {
      addedItems.value.push(action["added"].element);
    }
  } else if(eventType === "removed") {
    const index = addedItems.value.findIndex(element => element.value === action.removed.element.value);
    if (index !== -1) {
      addedItems.value.splice(index, 1);
    } else {
      removedItems.value.push(action["removed"].element);
    }
  }
  change();
  emit("data-changed", {added: addedItems.value, removed: removedItems.value});
}


function elementClicked(element: types.ArrayValue, type: "left" | "right") {
  if(!props.paginated) {
    return;
  }
  if(type === "left") {
    const index = addedItems.value.findIndex(item => item.value === element.value);
    if (index !== -1) {
      addedItems.value.splice(index, 1);
    } else {
      removedItems.value.unshift(element);
    }
  } else {
    const index = removedItems.value.findIndex(item => item.value === element.value);
    if (index !== -1) {
      removedItems.value.splice(index, 1);
    } else {
      addedItems.value.unshift(element);
    }
  }
  emit("data-changed", {added: addedItems.value, removed: removedItems.value});
}

function change() {
  emit('input', valueList.value);
}

function handleDragStart (){
  isHovered.value = false
}

function handleDragEnd(){
  isHovered.value = true
}

function moveRight() {
  valueList.value = allItems.value;
  listValue.value = [];
  change();
}

function moveLeft() {
  listValue.value = allItems.value;
  valueList.value = [];
  change();
}

const getVisibilityMap = (query: string, type: "left" | "right") => {
  if(props.paginated) {
    clearTimeout(bounceTimer);
    bounceTimer = setTimeout(() => {
      if(type === "left") {
        stateLeft.reset();  
        emit('search-left', {search: currentSearchLeft.value, state: stateLeft});
      } else {
        stateRight.reset();  
        emit('search-right', {search: currentSearchRight.value, state: stateRight});
      }
    }, 500)
    
    return;
  }
  const allItemsFlat = allItems.value.flat();
   return new Set(
     allItemsFlat.filter((item: types.ArrayValue) => {
        const itemText = item.text.toLowerCase();
        const currentSearchLeftLower = query.toLowerCase();
        return currentSearchLeftLower ? itemText.indexOf(currentSearchLeftLower) > -1 : false;
      }
    )
  )
}

</script>

<style lang="scss">
@import "../../assets/styles/main";

.drag-n-drop {
  max-width: 570px;
  display: grid;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  gap: 24px;
  .area__move-button {
    &--right {
      margin-left: auto;
      display: block;
      padding-left: 8px;
      >div {
        display: flex;
        align-items: center;
      }
    }
    &--left {
      padding-right: 8px;
      >div {
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }
  .part {
    &__title {
      color: $secondary;
      margin-bottom: 8px;
      @include body-2;
    }
    &__area {
      @include eewc-scrollbar;
      box-shadow: inset 0 0 0 1px $elements;
      background: $backgrounds;
      height: 236px;
      overflow: overlay;
      border-radius: 4px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
  .area {
    &__list {
      flex-grow: 1;
    }
    &__placeholder{
      @include body-2;
      color: $secondaryMedium;
    }
    &__item {
      height: 28px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      padding: 4px 8px;
      @include body-2;
      color: $primaryMedium;
      border-radius: 4px;
      background: $primaryWhite;
      filter: drop-shadow(0px 2px 12px rgba(42, 52, 64, 0.08));
      cursor: pointer;
      user-select: none;
      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &.sortable-chosen:not(.sortable-ghost) {
        background: $accent;
        color: $primaryWhite;
      }
      &--hovered:hover {
        background: $accentClear;
      }
    }
  }
  &--full-width {
    max-width: unset;
  }
  &__search {
    padding-bottom: 12px;
  }

  .infinite-loader {
    text-align: center;

    &--margin {
      margin-top: 0px;
    }
  }
}
</style>
