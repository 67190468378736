<template>
  <v-checkbox
    v-model="checked"
    refs="checkbox"
    :indeterminate-icon="iconIndeterminate"
    :on-icon="iconOn"
    :off-icon="iconOff"
    :ripple="false"
    :disabled="!!disabled"
    :label="label ? label : ''"
    :class="['eewc-checkbox', {
      'eewc-checkbox--normal': !checked && !disabled && !indeterminate,
      'eewc-checkbox--indeterminate': indeterminate && !disabled,
      'eewc-checkbox--active': checked && !disabled && !indeterminate,
      'eewc-checkbox--disabled': !!disabled
    }]"
    :indeterminate="!!indeterminate"
    :color="color ? color : 'accent'"
    @click.stop
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

const props = defineProps<{
  value: boolean
  label?: string
  indeterminate?: boolean
  disabled?: boolean
  color?:string
}>()

const emit = defineEmits<{
  (e: 'input', value: boolean): void;
  (e: 'change', value: boolean): void;
}>()

const iconOn = ref<string>("$icon_checkboxOn")
const iconOff = ref<string>("$icon_checkboxOff")
const iconIndeterminate = ref<string>("$icon_checkBoxindeterminate")

const checked = computed({
  get() {
    return props.value
  },
  set(val: boolean) {
    emit("input", val)
    emit("change", val)
  }
})

</script>

<style lang="scss">
@import "../../assets/styles/main";

.v-input {
  &--selection-controls__input {
    &:hover, &:focus {
      background: none;
      transform: none;
      transition: none;
    }
  }
}

.eewc-checkbox {
  margin-top: 0;
  padding-top: 0;

  &--normal {
    svg {
      color: $primary;
      &:active, &:focus {
        color: $secondary;
      }
    }
  }

  &--indeterminate {
    span * {
      color: $accent !important;
      &:active, &:focus {
        color: $accentDark !important;
      }
    }
  }

  &--active {
    span * {
      color: $accent !important;

      &:active, &:focus {
        color: $accentDark !important;
      }
    }
  }

  &--disabled {
    span * {
      color: $secondaryLight !important;
    }
  }

  .v-messages {
    position: absolute;
    bottom: -24px;
    left: -12px;
  }

  .v-input__slot {
    margin-bottom: 0;
  }

  &.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .v-input--selection-controls__input {
    height: 24px;
    width: 24px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      svg {
        box-shadow: 1px 1px 1px rgba(33, 42, 52, 0.32);
        border-radius: 4px;
        // to not clip the path
        overflow: visible;
        background: $primaryWhite !important;
      }
    }
  }

  .v-icon, svg {
    height: 16px !important;
    width: 16px !important;
  }

  span {
    z-index: 2;
  }

  label {
    @include subtitle-2;
    color: $primary !important;
    margin-left: 8px;
  }
}
</style>
