<template>
  <draggable
    class="drag-area"
    tag="div"
    :list="items"
    :group="{ name: 'group' }"
    :move="onMove"
    :disabled="disableDrag"
    @end="onChange"
    @start="onStart"
  >
    <div
      v-for="el in items"
      :key="el.id"
      class="row-nested-container"
    >
      <div
        :class="`row-item ${(expanded.includes(el.id) && el.children.length) ? 'row-item--expanded' : ''} ${dropzoneItem === el.id.toString() ? 'row-item--dropzone' : ''}`"
        :data-index="el.id"
        :style="{ gridTemplateColumns: `repeat(${headersLength - (containsActions ? 1 : 0)}, 1fr) ${containsActions ? '48px' : ''}` }"
      >
        <slot
          name="row"
          :item="el"
          :expand="() => expandRows(el.id)"
          :is-expanded="expanded.includes(el.id) && el.children.length"
        />
      </div>
      <div
        v-if="selectable && el.isSelectable"
        :class="['row-selector', {'row-selector--block': selectedRows.includes(el.id)}]"
      >
        <check-box 
          :value="selectedRows.includes(el.id)"
          @change="() => selectRowItem(el.id)" 
        />
      </div>
      <nested-draggable
        :class="`nested ${expanded.includes(el.id) ? 'expanded' : ''}`"
        :items="el.children"
        :on-change="onChange"
        :on-move="onMove"
        :contains-actions="containsActions"
        :dropzone-item="dropzoneItem"
        :on-start="onStart"
        :is-drag-area-expanded="isDragAreaExpanded"
        :headers-length="headersLength"
        :disable-drag="disableDrag"
      >
        <template #row="slotData">
          <slot
            name="row"
            v-bind="slotData || {}"
          />
        </template>
      </nested-draggable>
    </div>
  </draggable>
</template>

<script setup lang="ts">
import { ref } from "vue";
import draggable, { MoveEvent } from "vuedraggable";
import { TableItem } from './types';
import CheckBox from '../../components/selection-controls/CheckBox.vue'

const props = withDefaults(defineProps<{
  items: TableItem[],
  onChange: () => void;
  onMove: (event: MoveEvent<TableItem>) => void;
  onStart: () => void;
  headersLength: number;
  isDragAreaExpanded: boolean;
  containsActions: boolean;
  dropzoneItem: string;
  disableDrag: boolean;
  selectedRows?: (string| number)[],
  selectable?: boolean,
}>(), {
  disableDrag: false,
  selectedRows: () => [],
  selectable: false,
});

const emit = defineEmits<{
  (e: "input", value: TableItem[]): void;
  (e: 'row-select', item: number | (string | number)[] | string): void;
}>();
const expanded = ref<(number | string)[]>([]);

function expandRows(rowId: number | string) {
  if(props.items.find(item => item.id === rowId)?.children.length){
    expanded.value = expanded.value.includes(rowId) ? expanded.value.filter(id => id !== rowId) : expanded.value.concat(rowId);
  }
}

function selectRowItem(item: number | string) {
  emit('row-select', item);
}

</script>

<script lang="ts">
  export default {
    name: 'NestedDraggable',
  }
</script>

<style lang="scss">
@import "../../assets/styles/main";

.drag-area {
  .sortable-drag {
    background: unset !important;
    > .row-item {
      background: $accent !important;
      border-radius: 4px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      * {
        color: $primaryWhite !important;
      }
    }
  }
  
  .sortable-ghost {
    > .row-item {
      background: $elements !important;
      * {
        color: $secondaryMedium !important;
      }
    }
  }

  .row-nested-container {
    &:nth-child(even) {
      background: $backgrounds;
    }

    &:hover {
      .row-selector {
        display: block;
      }
      background: $accentClear;
    }
  }
  &:not(.nested) > .row-nested-container {
    padding-left: 0;
  }
  &.nested {
    > * {
      display: none;
    }
    &.expanded {
      > * {
        display: block;
      }
    }
  }

  .row-item {
    height: 40px;
    display: grid;
    align-items: center;
    @include body-2;
    & > * {
      padding-left: 12px;
      height: 40px;
      display: grid;
      align-items: center;
      color: $primary;
      &:first-child {
        padding-left: 32px;
      }
      &:last-child {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
    &:hover {
      background: $accentClear;
    }
    &--expanded {
      background: $accentLight !important;
    }
    &--dropzone {
      background: $accentClear !important;
      box-shadow: inset 0 0 0 1px $accent;
    }
  
    &--expanded, &--dropzone, &:hover {
      .rowItem__cellItem:first-child {
        * {
          color: $accent !important;
        }
      }
    }
  }
  .row-selector {
    display: none;
    position: absolute; 
    margin-top: -30px !important; 
    margin-left: 8px;

    .v-input--selection-controls__input {
      height: 20px !important;
      width: 20px !important;
    }

    .v-icon, svg {
      height: 12px !important;
      width: 12px !important;
    }

    &--block {
      display: block;
    }
  }
}
</style>