<template>
  <div>
    <date-picker
      v-model="selectedRange"
      :append-to-body="shouldAppendToBody"
      :class="['mx-dt-picker', isFullWidth && 'mx-dt-picker--full-width']"
      :clearable="false"
      :confirm-text="doneText"
      :disabled-date="disabledDate"
      :disabled-time="disabledTime"
      :disabled="isDisabled"
      :editable="false"
      :lang="lang"
      :placeholder="placeholder"
      :range="isRange"
      :shortcuts="shortcuts"
      :type="type"
      confirm
      popup-class="mx-dt-picker-popup"
    >
      <template #footer>
        <button-common
          v-if="isClearable"
          type="clear"
          :text="clearText"
          color="depressed"
          @click="handleClearClick"
        />
      </template>
    </date-picker>
  </div>
</template>

<script lang="ts" setup>
import { withDefaults, defineEmits, defineProps, computed } from "vue";
import DatePicker from "vue2-datepicker";

import ButtonCommon from "../buttons/ButtonCommon.vue";

import "vue2-datepicker/index.css";

/**
 * Types
 */

type DateInput = Date | null | [Date, Date] | [null, null];

type Props = {
  clearText: string;
  disabledDate?: (date: Date, currentValue: Date[]) => boolean;
  disabledTime?: (date: Date) => boolean;
  doneText: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isRange?: boolean;
  monthNames: Array<string>;
  placeholder: string;
  shouldAppendToBody?: boolean;
  sidebarOptions: Array<string>;
  type?: "date" | "datetime";
  value?: DateInput;
  weekDayNames: Array<string>;
};

/**
 * Utils
 */

const getCurrentDayDateRange = () => {
  const currentDate = new Date();

  const currentDayStart = new Date(currentDate);
  currentDayStart.setHours(0, 0, 0, 0);

  const currentDayEnd = new Date(currentDate);

  return [currentDayStart, currentDayEnd];
};

const getYesterdayDateRange = () => {
  const currentDate = new Date();

  const yesterdayStartDate = new Date(currentDate);
  yesterdayStartDate.setDate(yesterdayStartDate.getDate() - 1);
  yesterdayStartDate.setHours(0, 0, 0, 0);

  const yesterdayEndDate = new Date(currentDate);
  yesterdayEndDate.setDate(yesterdayEndDate.getDate() - 1);
  yesterdayEndDate.setHours(23, 59, 59, 999);

  return [yesterdayStartDate, yesterdayEndDate];
};

const getCurrentWeekDateRange = () => {
  const currentDate = new Date();
  const currentWeekEndDate = new Date(currentDate);
  const currentWeekStartDate = new Date(currentDate);

  // 0 = sun, 1 = mon, ..., 6 = sat
  const currentDayOfWeek = currentDate.getDay();

  currentWeekStartDate.setDate(
    currentWeekStartDate.getDate() -
      currentDayOfWeek +
      (currentDayOfWeek === 0 ? -6 : 1)
  );
  currentWeekStartDate.setHours(0, 0, 0, 0);

  return [currentWeekStartDate, currentWeekEndDate];
};

const getLastWeekDateRange = () => {
  const currentDate = new Date();
  const lastWeekEndDate = new Date(currentDate);
  const lastWeekStartDate = new Date(currentDate);

  // 0 = sun, 1 = mon, ..., 6 = sat
  const currentDayOfWeek = currentDate.getDay();

  lastWeekStartDate.setDate(
    lastWeekStartDate.getDate() -
      currentDayOfWeek +
      (currentDayOfWeek === 0 ? -6 : 1) -
      7
  );
  lastWeekStartDate.setHours(0, 0, 0, 0);

  lastWeekEndDate.setDate(
    lastWeekEndDate.getDate() -
      currentDayOfWeek +
      (currentDayOfWeek === 0 ? -6 : 1) -
      1
  );
  lastWeekEndDate.setHours(23, 59, 59, 999);

  return [lastWeekStartDate, lastWeekEndDate];
};

const getLast2WeeksDateRange = () => {
  const currentDate = new Date();
  const lastWeekEndDate = new Date(currentDate);
  const lastWeekStartDate = new Date(currentDate);

  // 0 = sun, 1 = mon, ..., 6 = sat
  const currentDayOfWeek = lastWeekStartDate.getDay();

  lastWeekStartDate.setDate(
    lastWeekStartDate.getDate() -
      currentDayOfWeek +
      (currentDayOfWeek === 0 ? -6 : 1) -
      14
  );
  lastWeekStartDate.setHours(0, 0, 0, 0);

  lastWeekEndDate.setDate(
    lastWeekEndDate.getDate() -
      currentDayOfWeek +
      (currentDayOfWeek === 0 ? -6 : 1) -
      1
  );
  lastWeekEndDate.setHours(23, 59, 59, 999);

  return [lastWeekStartDate, lastWeekEndDate];
};

const getLastMonthDateRange = () => {
  const currentDate = new Date();

  const lastMonthStartDate = new Date(currentDate);
  lastMonthStartDate.setMonth(lastMonthStartDate.getMonth() - 1);
  lastMonthStartDate.setDate(1);
  lastMonthStartDate.setHours(0, 0, 0, 0);

  const lastMonthEndDate = new Date(currentDate);
  lastMonthEndDate.setMonth(lastMonthEndDate.getMonth());
  lastMonthEndDate.setDate(0);
  lastMonthEndDate.setHours(23, 59, 59, 999);

  return [lastMonthStartDate, lastMonthEndDate];
};

const getCurrentMonthDateRange = () => {
  const currentDate = new Date();

  const currentMonthStartDate = new Date(currentDate);
  currentMonthStartDate.setDate(1);
  currentMonthStartDate.setHours(0, 0, 0, 0);

  const currentMonthEndDate = new Date(currentDate);

  return [currentMonthStartDate, currentMonthEndDate];
};

/**
 * Component
 */

const props = withDefaults(defineProps<Props>(), {
  disabledDate: undefined,
  disabledTime: undefined,
  isDisabled: false,
  isFullWidth: false,
  shouldAppendToBody: false,
  isClearable: false,
  type: "datetime",
  value: null,
});

const emit = defineEmits<{
  (e: "input", value: DateInput): void;
}>();

const selectedRange = computed({
  get: () => props.value,
  set: (newValue) => {
    emit("input", newValue);
  },
});

const shortcuts = computed(() => {
  return props.isRange
    ? [
        {
          text: props.sidebarOptions[0],
          onClick() {
            return getCurrentDayDateRange();
          },
        },
        {
          text: props.sidebarOptions[1],
          onClick() {
            return getYesterdayDateRange();
          },
        },
        {
          text: props.sidebarOptions[2],
          onClick() {
            return getCurrentWeekDateRange();
          },
        },
        {
          text: props.sidebarOptions[3],
          onClick() {
            return getLastWeekDateRange();
          },
        },
        {
          text: props.sidebarOptions[4],
          onClick() {
            return getLast2WeeksDateRange();
          },
        },
        {
          text: props.sidebarOptions[5],
          onClick() {
            return getCurrentMonthDateRange();
          },
        },
        {
          text: props.sidebarOptions[6],
          onClick() {
            return getLastMonthDateRange();
          },
        },
      ]
    : [];
});

const lang = computed(() => {
  return {
    formatLocale: {
      firstDayOfWeek: 1,
      months: props.monthNames,
      weekdaysMin: props.weekDayNames,
    },
    monthFormat: "MMMM",
  };
});

const handleClearClick = () => {
  let newDateRange: DateInput = null;

  if (props.isRange) {
    newDateRange = [null, null];
  }

  selectedRange.value = newDateRange;
};
</script>

<style lang="scss">
@import "../../assets/styles/main";

@mixin arrow-btn {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background: $elements;
  }

  &:active {
    background: $secondaryLight;
  }

  &:focus-visible {
    border: 1px solid $primary;
  }
}

@mixin arrow-btn-dark {
  &:hover {
    background: $primaryLight;
  }

  &:active {
    background: $secondaryMedium;
  }

  &:focus-visible {
    background: none;
    border: 1px solid $primaryWhite;
  }
}

@mixin calender-cell {
  .mx-date-row {
    .cell {
      &.active {
        background: $accent !important;
        color: $primaryWhite !important;
        position: relative;

        &::before {
          background: $accentLight;
          position: absolute;
          z-index: -1;
          pointer-events: none;
          content: "";
          display: inline-block;
          width: 33px;
          height: 32px;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

// Custom pop-up class
.mx-dt-picker-popup {
  margin-top: 4px !important;
  border-radius: 4px;
  border: 1px solid $elements;
  box-shadow: 2px 4px 12px rgba(42, 52, 64, 0.08);
  padding: 4px 0 4px 0px;
  font-family: Roboto, sans-serif;
  transform: none;
  background: $backgrounds !important;

  .mx-datepicker-popup-range {
    .mx-calendar {
      width: 230px !important;
    }
  }

  .mx-time {
    background: $backgrounds !important;
  }

  .mx-time-header {
    background-color: $backgrounds !important;
  }

  .mx-icon-right:before,
  .mx-icon-left:before {
    border-width: 3px 0 0 3px;
    border-radius: 0;
  }

  .mx-datepicker-sidebar {
    width: 194px !important;
    padding: 4px 4px 4px 0;
    margin-left: 4px;
    height: 271px;
    border-bottom: 1px solid $elements;
    border-right: 1px solid $elements;

    + .mx-datepicker-content {
      margin-left: 194px !important;
      border-left: none;
    }

    .active {
      background: $accent;
      color: $primaryWhite !important;

      &:hover {
        background: $accent !important;
      }
    }

    .mx-select-btn-text {
      padding: 4px 8px !important;
      width: 100%;
      @include body-2;
      border-radius: 4px;

      &:hover {
        background: $accentClear;
      }

      &:active {
        background: $secondaryLight;
      }

      &:focus-visible {
        border: 1px solid $primary;
      }
    }
  }

  .mx-calendar {
    width: 233px;
  }

  .mx-datepicker-footer {
    display: flex;
    align-items: baseline;
    justify-content: end;
    border-top: 1px solid $elements;
    margin: 0 4px;
    padding: 6px 0;

    .mx-datepicker-btn-confirm {
      padding: 8px 16px;
      border-radius: 4px;

      &:hover {
        background: $accentClear;
      }

      &:focus-visible {
        background: none;
        border: 1px solid $primary;
      }

      &:active {
        background: $accentLight;
      }
    }
  }

  .mx-btn-text {
    @include subtitle-2;
    color: $primary;
  }

  .mx-calendar-panel-month,
  .mx-calendar-panel-year {
    padding: 8px 4px 4px 4px !important;

    .mx-calendar-header-label {
      pointer-events: none;
    }

    .mx-calendar-header {
      color: $primary;
      @include subtitle-2;
      text-align: center;
    }

    .mx-btn-icon-double-left,
    .mx-btn-icon-double-right {
      padding: 0;
    }

    .mx-icon-double-right {
      @include arrow-btn;
      padding-right: 2px;
      padding-top: 1px;

      &:before {
        left: 6px;
        border-width: 3px 0 0 3px;
        border-radius: 0;
      }

      &:after {
        left: -4px;
      }
    }

    .mx-icon-double-left {
      @include arrow-btn;
      padding-top: 1px;

      &:before {
        left: 5px;
        border-width: 3px 0 0 3px;
        border-radius: 0;
      }

      &:after {
        left: -5px;
      }
    }

    .mx-calendar-header-label {
      button {
        margin-top: 4px;
      }
    }

    .mx-table-year,
    .mx-table-month {
      -webkit-border-horizontal-spacing: 12px;
      -webkit-border-vertical-spacing: 23px;
      @include body-2;
      text-align: center;
      color: $primary;

      .cell {
        &:hover {
          color: $primary;
          background: $accentClear;
          border-radius: 4px;
        }
      }

      .active {
        background: $accent !important;
        border-radius: 4px;

        &:hover {
          color: $primaryWhite;
        }
      }
    }

    .mx-table-month {
      width: 180px;
      margin: auto;
      border-spacing: 0;
      -webkit-border-vertical-spacing: 23px;
    }

    .mx-table-year {
      width: 180px;
      margin: auto;
      border-spacing: 0;
      -webkit-border-vertical-spacing: 14px;
      -webkit-border-horizontal-spacing: 20px;
    }
  }

  .mx-calendar-panel-date {
    padding: 8px 4px 4px 4px !important;

    .mx-icon-double-right,
    .mx-icon-double-left {
      display: none;
    }

    .mx-btn-current-year {
      pointer-events: none;
    }

    .mx-btn-current-month {
      margin-top: 4px;
    }

    .mx-btn-icon-left,
    .mx-btn-icon-right {
      @include arrow-btn;
    }

    .mx-btn-text:hover {
      color: $primary;
    }

    .mx-calendar-header {
      color: $primary;
      @include subtitle-2;
      text-align: center;
    }
  }

  .mx-datepicker-footer .mx-btn {
    border: none;
    @include subtitle-2;
    color: $accent;
  }

  .mx-table {
    width: 222px;
  }

  .mx-table-date th {
    color: $secondaryMedium;
    @include subtitle-2;
    text-align: center;
  }

  .mx-date-row {
    .cell {
      @include body-2;
      color: $primary;
      border-radius: 50%;
      text-align: center;

      &.not-current-month {
        background: unset !important;
        pointer-events: none;
        color: $secondaryMedium !important;
      }

      &.today {
        @include subtitle-2;
        text-align: center;
        color: $accent;
      }

      &.active {
        color: $primaryWhite;
        background-color: $accent;

        &:hover {
          background-color: $accent;
          color: $primaryWhite;
        }

        &::before {
          border-radius: 33px 0 0 33px;
          background: $accentLight;
        }
      }

      &:hover {
        background: $accentLight;
        color: $primary;
      }

      &.in-range {
        background: $accentLight;
        color: $primary;
        border-radius: 0;
      }

      &.hover-in-range {
        background: none;
        border-radius: 0;
        border-top: 1px dashed $elements;
        border-bottom: 1px dashed $elements;
        color: $primary;
      }

      &.disabled {
        background: unset;
        color: $secondaryLight;
        cursor: auto;
      }
    }

    .in-range ~ .active {
      &::before {
        border-radius: 0 33px 33px 0;
        background: $accentLight;
      }
    }
  }

  &__dark {
    background-color: $primary !important;
    border: 1px solid $primaryMedium !important;
    box-shadow: 2px 4px 12px rgba(42, 52, 64, 0.08);

    .mx-calendar-panel-date {
      .mx-btn-text:hover {
        color: $primaryWhite;
      }

      .mx-calendar-header {
        * {
          color: $primaryWhite;
        }

        .mx-btn-icon-left,
        .mx-btn-icon-right {
          @include arrow-btn-dark;
        }
      }

      .mx-table-date {
        .not-current-month {
          color: $secondary !important;
        }

        .cell {
          color: $primaryWhite;

          &.today:not(.active) {
            color: $accent;

            &:active {
              color: $primaryWhite;
            }
          }

          &.disabled {
            color: $secondary;
            pointer-events: none;
          }

          &:hover:not(.disabled) {
            background: $accentLight;
            color: $primary;
          }

          &:active {
            background: $accent !important;
          }

          &.active {
            background: $accentDark !important;

            &:hover {
              background: $accentDark !important;
              color: $primaryWhite;
            }
          }
        }
      }
    }

    .mx-calendar-panel-month {
      .mx-calendar-header {
        * {
          color: $primaryWhite;
        }

        .mx-icon-double-right,
        .mx-icon-double-left {
          @include arrow-btn-dark;
        }
      }

      .mx-table-month {
        .cell {
          color: $primaryWhite;

          &:hover {
            background: $accentDark;
            border-radius: 4px;
            color: $primaryWhite;
          }

          &.active:hover {
            background: $accent;
          }
        }
      }
    }

    .mx-datepicker-footer {
      border-color: $primaryMedium;

      .clearBtn {
        color: $primaryWhite;

        &:hover {
          background: $primaryLight !important;
        }

        &:focus-visible {
          background: none;
          border: 1px solid $primaryWhite;
        }

        &:active {
          background: $secondaryMedium !important;
        }
      }

      .mx-datepicker-btn-confirm {
        &:hover {
          background: $primaryLight;
        }

        &:focus-visible {
          background: none;
          border: 1px solid $accent;
        }

        &:active {
          background: $accentLight;
        }
      }
    }
  }

  .mx-datepicker-sidebar {
    display: flex-row;

    button.mx-btn.mx-btn-text.mx-btn-shortcut {
      padding: 4px 8px !important;
      width: 100%;
      @include body-2;
      border-radius: 4px;

      &:hover {
        background: $accentClear;
      }

      &:active {
        background: $secondaryLight;
      }

      &:focus-visible {
        border: 1px solid $primary;
      }
    }
  }
}

// Custom picker class
.mx-dt-picker {
  .mx-input {
    text-align: left !important;
    background: $backgrounds !important;
  }

  .mx-input-wrapper {
    .datepicker-input-field {
      .v-input__slot {
        & fieldset {
          color: $accent !important;
          border: 1px solid $accent !important;
        }
      }

      &__dark {
        .v-input .v-input__slot {
          fieldset {
            background: unset;
            backdrop-filter: unset;
            border: unset;
          }

          &:focus-within {
            & fieldset {
              color: $accent !important;
              border: 1px solid $accent !important;
            }
          }

          input,
          svg {
            color: $primaryWhite !important;
          }
          background: rgba(33, 42, 52, 0.72);
          backdrop-filter: blur(2px);
        }
      }
    }
  }

  .input-placeholder-text {
    position: absolute;
    top: 8px;
    z-index: 2;
    color: $secondaryMedium;
    @include body-2;
    padding-left: 10px;
    margin-left: 2px;
    background-color: $backgrounds;
  }

  &--full-width {
    width: 100%;
  }
}

// Custom full width class
.mx-dt-picker--full-width {
  width: 100% !important;
}
</style>
